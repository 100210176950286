import { useStore } from '@nuxtjs/composition-api'
import useStoreData from '@/composables/useStoreData'

export default () => {
  const { storeRouteParams } = useStoreData()
  const store = useStore()

  const fetchKycFeatures = async () => {
    try {
      await store.dispatch('kyc/fetchFeatures', storeRouteParams.value)
    } catch (error) {
      /* Add console.error to track error in datadog */
      console.error('An error occurred when fetching kyc feature', error)
      throw error
    }
  }

  const fetchKycProvider = async () => {
    try {
      await store.dispatch('kyc/fetchProvider', storeRouteParams.value)
    } catch (error) {
      /* Add console.error to track error in datadog */
      console.error('An error occurred when fetching kyc provider', error)
      throw error
    }
  }

  return {
    fetchKycFeatures,
    fetchKycProvider
  }
}
