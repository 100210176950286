export default {
  action: {
    previousPage: 'BoAsso_Form_Action_PreviousPage',
    nextPage: 'BoAsso_Form_Action_NextPage',
    seePage: 'BoAsso_Form_Action_SeePage'
  },
  address: {
    place: 'BoAsso_Form_Address_Place',
    tooltip: 'BoAsso_Form_Address_Tooltip',
    autocomplete: 'BoAsso_Form_Address_Autocomplete'
  },
  addressFields: {
    button: 'BoAsso_Form_AddressFields_Button',
    orgAddress: 'BoAsso_Form_AddressFields_OrgAddress',
    zipCode: 'BoAsso_Form_AddressFields_ZipCode',
    city: 'BoAsso_Form_AddressFields_City',
    country: 'BoAsso_Form_AddressFields_Country',
    latitude: 'BoAsso_Form_AddressFields_Latitude',
    longitude: 'BoAsso_Form_AddressFields_Longitude'
  },
  addAdminModal: {
    email: 'BoAsso_Form_AddAdminModal_Email',
    close: 'BoAsso_Form_AddAdminModal_Close',
    submit: 'BoAsso_Form_AddAdminModal_Submit'
  },
  description: {
    name: 'BoAsso_Form_Name',
    type: 'BoAsso_Form_Type',
    other: 'BoAsso_Form_Other'
  },
  admin: {
    openModal: 'BoAsso_Form_Admin_OpenModal'
  },
  dateTimeField: {
    date: 'BoAsso_Form_DateTimeField_Date',
    fromDate: 'BoAsso_Form_DateTimeField_FromDate',
    toDate: 'BoAsso_Form_DateTimeField_ToDate',
    fromTime: 'BoAsso_Form_DateTimeField_FromTime',
    toTime: 'BoAsso_Form_DateTimeField_ToTime'
  },
  durationField: {
    dateTimeDuration: 'BoAsso_Form_DurationField_DateTimeDuration'
  },
  attendeesField: {
    limitTotalAttendees: 'BoAsso_Form_AttendeesField_LimitTotalAttendees',
    attendeesLimit: 'BoAsso_Form_AttendeesField_AttendeesLimit',
    displayRemainingEntries: 'BoAsso_Form_AttendeesField_DisplayRemainingEntries'
  },
  donationField: {
    allowDonation: 'BoAsso_Form_DonationField_AllowDonation',
    donationAmount: 'BoAsso_Form_DonationField_DonationAmount',
    allowFreeDonation: 'BoAsso_Form_DonationField_AllowFreeDonation'
  },
  ticketingSettings: {
    emitWithHa: 'BoAsso_Form_TickettingSettings_EmitWithHa'
  },
  ticketingStatus: {
    tooltip: 'BoAsso_Form_TicketingStatus_Tooltip',
    published: 'BoAsso_Form_TicketingStatus_Published',
    public: 'BoAsso_Form_TicketingStatus_Public'
  },
  validityDateField: {
    determineValidity: 'BoAsso_Form_ValidityDateField_DetermineValidity',
    validityStartDate: 'BoAsso_Form_ValidityDateField_ValidityStartDate',
    validityStartTime: 'BoAsso_Form_ValidityDateField_ValidityStartTime',
    validityEndDate: 'BoAsso_Form_ValidityDateField_ValidityEndDate',
    validityEndTime: 'BoAsso_Form_ValidityDateField_ValidityEndTime'
  },
  promoCodes: {
    add: 'BoAsso_Form_PromoCodeModal_Add'
  },
  promoCodeModal: {
    code: 'BoAsso_Form_PromoCodeModal_Code',
    type_fixed: 'BoAsso_Form_PromoCodeModal_TypeFixed',
    type_percentage: 'BoAsso_Form_PromoCodeModal_TypePercentage',
    fixedAmount: 'BoAsso_Form_PromoCodeModal_FixedAmount',
    percentageAmount: 'BoAsso_Form_PromoCodeModal_PercentageAmount',
    priceType_all: 'BoAsso_Form_PromoCodeModal_ConcernedPricesAll',
    priceType_custom: 'BoAsso_Form_PromoCodeModal_ConcernedPricesCustom',
    limitAvailability: 'BoAsso_Form_PromoCodeModal_LimitAvailability',
    validityStartDate: 'BoAsso_Form_PromoCodeModal_ValidityStartDate',
    validityStartTime: 'BoAsso_Form_PromoCodeModal_ValidityStartTime',
    validityEndDate: 'BoAsso_Form_PromoCodeModal_ValidityEndDate',
    validityEndTime: 'BoAsso_Form_PromoCodeModal_ValidityEndTime',
    limitTotalUses: 'BoAsso_Form_PromoCodeModal_LimitTotalUses',
    totalUsesLimit: 'BoAsso_Form_PromoCodeModal_TotalUsesLimit'
  },
  optionModal: {
    confirm: 'BoAsso_Form_OptionModal_Confirm',
    cancel: 'BoAsso_Form_OptionModal_Cancel',
    type_fixed: 'BoAsso_Form_OptionModal_TypeFixed',
    type_free: 'BoAsso_Form_OptionModal_TypeFree',
    amount: 'BoAsso_Form_OptionModal_Amount',
    mandatory: 'BoAsso_Form_OptionModal_Mandatory',
    vat: 'BoAsso_Form_OptionModal_Vat',
    name: 'BoAsso_Form_OptionModal_Name',
    description: 'BoAsso_Form_OptionModal_Description',
    priceType_all: 'BoAsso_Form_OptionModal_PriceTypeAll',
    priceType_custom: 'BoAsso_Form_OptionModal_PriceTypeCustom',
    limit: 'BoAsso_Form_OptionModal_Limit',
    limitAmount: 'BoAsso_Form_OptionModal_LimitAmount',
    limitType_all: 'BoAsso_Form_OptionModal_LimitTypeAll',
    limitType_byPrice: 'BoAsso_Form_OptionModal_LimitTyp'
  },
  attendeesInformations: {
    add: 'BoAsso_Form_AttendeesInformations_Add'
  },
  attendeeInformationsModal: {
    questionAssignment: {
      affectationOptions: 'BoAsso_Form_AttendeeInformationsModal_AffectationOptions',
      selectorDropDown: 'BoAsso_Form_AttendeeInformationsModal_SelectorDropDown'
    },
    cancel: 'BoAsso_Form_AttendeeInformationsModal_Cancel',
    confirm: 'BoAsso_Form_AttendeeInformationsModal_Confirm',
    questionTitle: 'BoAsso_Form_AttendeeInformationsModal_QuestionTitle',
    questionType: 'BoAsso_Form_AttendeeInformationsModal_QuestionType',
    questionAssignmentGroupmyPrices:
      'BoAsso_Form_AttendeeInformationsModal_QuestionAssignmentGroupMyPrices',
    questionAssignmentGroupmyOptions:
      'BoAsso_Form_AttendeeInformationsModal_QuestionAssignmentGroupMyOptions'
  },
  contact: {
    email: 'BoAsso_Form_Contact_Email',
    phoneNumber: 'BoAsso_Form_Contact_PhoneNumber'
  },
  attendeesList: {
    dynamicScrollToTop: 'BoAsso_Attendees_AttendeesList_DynamicScrollUpButton',
    seeTicket: 'BoAsso_Attendees_AttendeesList_SeeTicketButton',
    loadMore: 'BoAsso_Attendees_AttendeesList_LoadMoreButton',
    bottomScrollToTop: 'BoAsso_Attendees_AttendeesList_BottomScrollUpButton',
    searchInput: 'BoAsso_Attendees_Filters_SearchBar',
    searchButton: 'BoAsso_Attendees_Filters_SearchButton'
  }
}
