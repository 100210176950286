import { haMoment, functions } from '@ha/helpers'

const sizeMultiples = ['o', 'Ko', 'Mo', 'Go']

/**
 * Format a file size to be human readable.
 * e.g. humanFileSize(12345) => '12.35 Ko'
 * @param {number} size in bytes
 * @returns {string}
 */
export function humanFileSize(size) {
  const i = Math.floor(Math.log(size) / Math.log(1000))
  return (size / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + sizeMultiples[i]
}

/**
 * Used to display an amount from API in euros with euros sign, or a placeholder string
 * @param {Number} val amount in cents to convert
 * @param {String} alt string to display if no amount
 */
export function getAmountInEuros(val, alt) {
  return val
    ? `${functions.convertToEuros(val).toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €`
    : alt
    ? alt
    : '0.00 €'
}

/**
 * Add the given amount of year to date
 * @param {Date} date date to increment
 * @param {Number} years amount of years to add
 * @returns {Number}
 */
export function addYear(date, years) {
  const result = haMoment(date).add(years, 'year').toDate()
  return result
}

/**
 * Difference in months between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @param {Number} years amount of years to add
 * @returns {Date}
 */
export function differenceInMonths(date1, date2) {
  const result = date1.diff(date2, 'months', true)
  return result ? result : 0
}

/**
 * Used to display date in format DD/MM/YYYY with leading 0 (eg: 02/02/2000)
 * @param {Date}
 * @returns {string}
 */
export function getFrenchDateFormat(date) {
  const d = new Date(date)
  const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
  const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()
  const year = d.getFullYear()
  const today = day + '/' + month + '/' + year
  return today
}

/**
 * @param {Date} date
 * @returns {Boolean}
 */
export function isLessThanOneYear(date) {
  const today = haMoment()
  return today.diff(haMoment(date), 'year') < 12
}

/**
 * get terms between 2 dates
 * @param {Date} date1
 * @param {Date} date2
 * @param {Number} amount amount of the term
 * @param {Number} termsDay day of the term
 * @param {String} validityType validity type of the form
 * @returns {Array} array of terms
 */
export function getTermsBetweenTwoDates(startDate, endDate, amount, termsDay, validityType) {
  const terms = []
  const currentMonth = startDate.month()
  const currentYear = startDate.year()

  let nbTerms = 10

  switch (validityType) {
    case 'Custom':
      // eslint-disable-next-line no-case-declarations
      const dayTermsEndDate = new Date(endDate)

      if (endDate.isSameOrBefore(haMoment(dayTermsEndDate).day(termsDay).startOf('day'), 'day')) {
        endDate = endDate.day(1).subtract(1, 'day')
      }
      nbTerms = Math.ceil(differenceInMonths(endDate, startDate.day(termsDay).startOf('day')))
      // show 12 terms max
      nbTerms = Math.min(nbTerms, 12)
      break
    case 'Illimited':
    default:
      nbTerms = 10
  }

  for (let i = 0; i < nbTerms; i++) {
    terms.push({
      Amount: amount,
      Date: haMoment().year(currentYear).month(currentMonth).date(termsDay).add(i, 'months')
    })
  }

  return terms
}
