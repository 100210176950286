
import { HaButton } from '@ha/components'
import ButtonLink from '@/components/globals/buttons/ButtonLink.vue'

export default {
  name: 'SidebarLinksFiscalReceipts',
  components: {
    ButtonLink,
    HaButton
  },
  props: {
    isLoading: Boolean,
    isEditing: Boolean
  },
  computed: {
    navigateBackBtn() {
      return {
        text: this.$t('views.sidebar.form.homeLink'),
        to: `/${this.organizationSlug}/accueil`
      }
    },
    organizationSlug() {
      return this.$route.params.organization
    }
  },
  methods: {
    isActive(path) {
      return this.$route.path.endsWith(path)
    },
    close() {
      this.$emit('close')
    },
    handleClickMainButton() {
      this.$router.push(this.navigateBackBtn.to)
      this.$emit('close')
    }
  }
}
