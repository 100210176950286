
import { functions, enum as enums } from '@ha/helpers'
import { HaButton } from '@ha/components'
import { UrlToApiTypes, Status } from '@/helpers/enums'
import ButtonLink from '@/components/globals/buttons/ButtonLink.vue'
import RefundModal from '@/components/layouts/sidebar/partials/RefundModal.vue'
import formatError from '@/helpers/format-error'
import useRouteParams from '@/composables/useRouteParams'
import useNotifications from '@/composables/useNotifications'
import useConfigEnv from '@/composables/useConfigEnv'
import useTracking from '@/composables/useTracking'

export default {
  name: 'FormSidebar',
  components: {
    ButtonLink,
    RefundModal,
    HaButton
  },
  props: {
    isLoading: Boolean,
    isEditing: Boolean
  },
  setup() {
    const { formActionType } = useRouteParams()
    const { notifyError, notifySuccess } = useNotifications()
    const { NUXT_ENV_AUTH_FRONT } = useConfigEnv()
    const { tracking } = useTracking()

    return { formActionType, notifyError, notifySuccess, NUXT_ENV_AUTH_FRONT, tracking }
  },
  data() {
    return {
      showRefundModal: false,
      refundEnabled: false,
      isNewUserFromAuthSuggestion: false,
      enums
    }
  },
  computed: {
    isFirstCreation() {
      const existingFormsTypes = this.$store.state.organizations.formTypes[this.organizationSlug]
      return (
        !this.isEditing &&
        !existingFormsTypes?.find((type) => {
          return type.toUpperCase() === UrlToApiTypes[this.$route.params.type]
        })
      )
    },
    navigateBackBtn() {
      return {
        text: this.tr(`_${this.$route.params.type}`),
        to: `/${this.organizationSlug}/${this.$route.params.type}`
      }
    },
    form() {
      return this.$store.state.forms.entities[this.formKey]
    },
    creationStatus() {
      return this.$store.state.forms.creation.status
    },
    formStatus() {
      return this.$store.state.forms.statuses[this.formKey]
    },
    forms() {
      return this.$store.state.forms.entities
    },
    refundAmount() {
      return this.form?.refundAmount
    },
    formSlug() {
      return this.$route.params.form
    },
    organizationSlug() {
      return this.$route.params.organization
    },
    organization() {
      return this.$store.state.organizations.enriched[this.$route.params.organization] || {}
    },
    formTitle() {
      return this.form?.title
    },
    links() {
      return [
        {
          text: this.tr('editLink'),
          to: `/${this.organizationSlug}/${this.$route.params.type}/${this.formSlug}/edition/1`,
          icon: ['fas', 'pen'],
          isActive: !!this.formActionType
        },
        {
          text: this.tr('shareLink'),
          to: `/${this.organizationSlug}/${this.$route.params.type}/${this.formSlug}/diffusion`,
          icon: ['fas', 'share-alt'],
          isActive: this.isActive(
            `/${this.organizationSlug}/${this.$route.params.type}/${this.formSlug}/diffusion`
          )
        },
        {
          text: this.tr(
            `${functions.capitalizeFirstLetter(
              UrlToApiTypes[this.$route.params.type].toLowerCase()
            )}Link`
          ),
          to: `/${this.organizationSlug}/${this.$route.params.type}/${this.formSlug}/statistiques`,
          icon: ['fas', 'user-friends'],
          isActive: this.isActive(
            `/${this.organizationSlug}/${this.$route.params.type}/${this.formSlug}/statistiques`
          )
        },
        {
          text: this.tr('adminsLink'),
          to: `/${this.organizationSlug}/${this.$route.params.type}/${this.formSlug}/administrateurs`,
          icon: ['fas', 'user-plus'],
          isActive: this.isActive(
            `/${this.organizationSlug}/${this.$route.params.type}/${this.formSlug}/administrateurs`
          )
        }
      ]
    },
    editing() {
      return !!this.form?.description
    },
    loading() {
      return this.formStatus === Status.LOADING || this.creationStatus === Status.LOADING
    },
    hasForms() {
      return !!this.forms?.length
    },
    provider() {
      return this.$store.state.compliance?.compliance?.paymentProvider?.type
    }
  },
  created() {
    if (this.$route.query.fromSuggestion) {
      this.isNewUserFromAuthSuggestion = true
      this.$router.replace({ fromSuggestion: null })
    }
  },
  methods: {
    goToSuggestion() {
      this.tracking.track('Account Creation - Back to suggestion', {
        associationType: this.organization.category
      })
    },
    openRefundModal() {
      if (Object.prototype.hasOwnProperty.call(this.form, 'refundAmount')) {
        this.showRefundModal = true
        return
      }
      this.$store
        .dispatch('forms/fetchRefundAmount', {
          orgSlug: this.organizationSlug,
          formSlug: this.formSlug,
          type: UrlToApiTypes[this.$route.params.type]
        })
        .then(() => {
          this.showRefundModal = true
        })
        .catch((error) => {
          this.notifyError(error, {
            title: this.tr('refundErrorTitle'),
            body: formatError(error, this.$i18n)
          })
        })
    },
    async refund() {
      try {
        await this.$store.dispatch('forms/askRefund', {
          orgSlug: this.organizationSlug,
          type: UrlToApiTypes[this.$route.params.type],
          formSlug: this.formSlug
        })
        this.showRefundModal = false
        this.notifySuccess({
          title: this.tr('refundSuccessTitle'),
          body: this.tr('refundSuccessBody')
        })
      } catch (error) {
        this.notifyError(error, {
          title: this.tr('refundErrorTitle'),
          body: formatError(error, this.$i18n)
        })
      }
    },
    isActive(link) {
      return link ? link.replace(/[0-9]/i) === this.$route.path.replace(/[0-9]/i, '') : false
    },
    tr(name) {
      return this.$t(`views.sidebar.form.${name}`)
    },
    close() {
      this.$emit('close')
    },
    handleClickMainButton() {
      this.$router.push(this.navigateBackBtn.to)
      this.$emit('close')
    }
  }
}
