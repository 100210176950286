import loginPage from '@/dataUx/loginUxDictionnary'
import home from '@/dataUx/homeUxDictionnary'
import form from '@/dataUx/formUxDictionnary'
import mainLayout from '@/dataUx/mainLayoutUxDictionnary'
import paymentTracking from '@/dataUx/paymentTracking'

/**
 * How to tag things: https://helloasso.atlassian.net/wiki/spaces/LPTDP/pages/198737937/Nomenclature+des+attributs+de+tracking
 */

export default {
  loginPage,
  home,
  form,
  mainLayout,
  paymentTracking
}
