import { ref } from 'vue'
import { useContext } from '@nuxtjs/composition-api'

const haToaster = ref(null)

export default () => {
  const { i18n } = useContext()

  const pushNotification = (toast) => {
    haToaster.value?.push(toast)
  }

  const notifyError = (error, params) => {
    /* Add console.error to track error in datadog */
    console.error(error)
    const status = error?.response?.status ?? 'default'
    const toast = {
      type: 'danger',
      title: params?.title ?? i18n.t(`notifications.errors.${status}.title`),
      body: params?.body ?? i18n.t(`notifications.errors.${status}.body`),
      timeout: params?.timeout ?? 7000
    }
    pushNotification(toast)
  }

  const notifySuccess = (params) => {
    const toast = {
      type: 'success',
      title: params?.title ?? i18n.t('notifications.successes.default.title'),
      body: params?.body ?? i18n.t('notifications.successes.default.body'),
      timeout: params?.timeout ?? 7000
    }
    pushNotification(toast)
  }

  const notifyInfo = (params) => {
    const toast = {
      type: 'info',
      title: params?.title,
      body: params?.body,
      timeout: params?.timeout ?? 7000
    }
    pushNotification(toast)
  }

  const notifyWarning = (params) => {
    const toast = {
      type: 'warning',
      title: params?.title,
      body: params?.body,
      timeout: params?.timeout ?? 7000
    }
    pushNotification(toast)
  }

  return { haToaster, notifyError, notifySuccess, notifyInfo, notifyWarning, pushNotification }
}
