import { PaymentParameters } from '../components/payment-parameters/paymentParamters.interface'
import { OptionCategories } from './enums'
import { DiscountCode } from '@/components/discount-codes/discountCode.interface'
import { Tier } from '@/components/tiers/Tiers.interface'

export type StoreRouteParams = { orgSlug: string; formSlug: string; type: FormType }

export type CustomFieldType =
  | 'TextInput'
  | 'Phone'
  | 'Zipcode'
  | 'Number'
  | 'ChoiceList'
  | 'Checkbox'
  | 'Switch'
  | 'YesNo'
  | 'Date'
  | 'FreeText'
  | 'File'

export enum Status {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING'
}

export enum OptionState {
  DISABLED = 'Disabled',
  ENABLED = 'Enabled'
}

export type ExtraOptionState = `${OptionState}`

export enum FormType {
  CrowdFunding = 'CrowdFunding',
  Donation = 'Donation',
  Event = 'Event',
  Membership = 'Membership',
  PaymentForm = 'PaymentForm',
  Checkout = 'Checkout',
  Shop = 'Shop'
}

export enum PaymentState {
  PENDING = 'Pending',
  AUTHORIZED = 'Authorized',
  REFUSED = 'Refused',
  UNKNOWN = 'Unknown',
  REGISTERED = 'Registered',
  ERROR = 'Error',
  REFUNDED = 'Refunded',
  REFUNDING = 'Refunding',
  WAITING = 'Waiting',
  CANCELED = 'Canceled',
  CONTESTED = 'Contested',
  WAITING_BANK_VALIDATION = 'WaitingBankValidation',
  WAITING_BANK_WITHDRAW = 'WaitingBankWithdraw',
  WAITING_AUTHENTICATION = 'waitingAuthentication',
  ABANDONED = 'Abandoned',
  AUTHORIZED_PREPROD = 'AuthorizedPreprod',
  CORRECTED = 'Corrected',
  DELETED = 'Deleted',
  INCONSISTENT = 'Inconsistent',
  NO_DONATION = 'NoDonation'
}

export interface CustomField {
  isPayerField: boolean
  linkedToAllTiers: boolean
  linkedToAllAdditionalOptions: boolean
  linkedTiers: any[]
  linkedAdditionalOptions: any[]
  id: number
  values: any[]
  isRequired: boolean
  type: CustomFieldType
  label: string
}

export interface ExtraOption {
  id: number | null
  label: string | null
  description: string | null
  price: number | null
  maxEntries: number | null
  vatRate: number
  customFields?: CustomField[]
  state?: ExtraOptionState
  isLinkedToAllTiers: boolean
  linkedTiers: number[]
  isRequired: boolean
  nbUses: number
}

export interface FrontEndExtraOption extends ExtraOption {
  type: keyof typeof OptionCategories
  hasMaxEntries: Boolean
  hasBeenUsed: Boolean
}

export interface Picture {
  fileName: string
  publicUrl: string
}

export enum WalletState {
  ENABLED = 'Enabled',
  LIMITED = 'Limited',
  RESTRICTED_SOON = 'RestrictedSoon',
  RESTRICTED = 'Restricted'
}

export type DataRouteTracking = {
  rubrik: string
  context: string
  category: string
  title: string
  organization_slug?: string
  form_type?: string
  form_slug?: string
}

export type NuxtRoute = {
  fullPath: string
  hash: string
  matched: Object[]
  meta: Object
  name: string
  params: {
    organization?: string
    type?: string
    form?: string
    step?: string
  }
  path: string
  query: Object
}

export interface MetaDate {
  createdAt: Date
  updatedAt: Date
}

export interface Form {
  acceptOpenDonation: boolean
  allowComment: boolean
  amountVisible: boolean
  displayParticipantsCount: boolean
  displayRemainingEntries: boolean
  financialGoal: number
  formPaymentTerms: PaymentParameters
  generateMembershipCards: boolean
  generateTickets: boolean
  invertDescriptions: boolean
  longDescription: string
  activityTypeId: number
  currency: string
  description: string
  endDate?: Date
  startDate?: Date
  saleEndDate?: string
  saleStartDate?: string
  maxEntries?: number
  meta: MetaDate
  state: string
  title: string
  widgetButtonUrl: string
  widgetFullUrl: string
  widgetVignetteHorizontalUrl: string
  widgetVignetteVerticalUrl: string
  formSlug: string
  formType: FormType
  url: string
  organizationSlug: string
  openDonationPresetAmounts?: number[]
  color?: string
  banner?: string
  thumbnail?: string
  refundAmount?: number
  conditionsAndTermsFile?: {
    publicUrl: string
  }
  personalizedMessage?: string
  participantCount?: number
  tiers?: Tier[]
  discountCodes?: DiscountCode[]
  validityType?: 'Custom' | 'MovingYear'
}
