import { filter, find, findIndex } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status, FormType, StoreRouteParams } from '../helpers/tsenums'
import { DiscountCode, DiscountCodeState } from '@/components/discount-codes/discountCode.interface'
import getStoreFormKey from '../helpers/getStoreFormKey'
import { isMembership, isShop, isEvent } from '@/helpers/forms/getFormType'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface Source {
  form: string | null
  organization: string | null
}

class State {
  items: DiscountCode[] = []
  status: Status = Status.SUCCESS
  fetchedFrom: Source = {
    form: null,
    organization: null
  }
}

export const state = () => new State()

export const getters = {
  hasLoadedDiscountCodes: (state) => (formSlug, orgSlug) => {
    return state.fetchedFrom.form === formSlug && state.fetchedFrom.organization === orgSlug
  }
}

export const mutations = <MutationTree<State>>{
  SET_DISCOUNT_CODES(
    state,
    {
      discountCodes,
      orgSlug,
      formSlug
    }: { discountCodes: DiscountCode[]; orgSlug: string; formSlug: string }
  ) {
    state.items = discountCodes
    state.fetchedFrom.form = formSlug
    state.fetchedFrom.organization = orgSlug
  },
  SET_DISCOUNT_CODES_STATUS(state, { status }: { status: Status }) {
    state.status = status
  },
  ADD_DISCOUNT_CODE(state, { discountCode }: { discountCode: DiscountCode }) {
    state.items = [...state.items, discountCode]
  },
  DELETE_DISCOUNT_CODE(state, { id }: { id: number }) {
    state.items = filter(state.items, (discountCode) => discountCode.id !== id)
  },
  ENABLE_DISCOUNT_CODE(state, { id }: { id: number }) {
    const discountCode = find(state.items, (item) => item.id === id)
    discountCode!.state = DiscountCodeState.ENABLED
  },
  DISABLE_DISCOUNT_CODE(state, { id }: { id: number }) {
    const discountCode = find(state.items, (item) => item.id === id)
    discountCode!.state = DiscountCodeState.DISABLED
  },
  SET_DISCOUNT_CODE(state, { discountCode }: { discountCode: DiscountCode }) {
    state.items.splice(
      findIndex(state.items, (item) => discountCode.id === item.id),
      1,
      discountCode
    )
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchDiscountCodes(
    { commit, getters },
    { routeParams, forceReload = false }: { routeParams: StoreRouteParams; forceReload: boolean }
  ) {
    const { orgSlug, formSlug, type } = routeParams
    if (!forceReload && getters.hasLoadedDiscountCodes(formSlug, orgSlug)) return Promise.resolve()

    /* Discount codes only exist for membership, shop and event */
    if (!isMembership(type) && !isShop(type) && !isEvent(type)) {
      commit('SET_DISCOUNT_CODES', { discountCodes: [], orgSlug, formSlug })
      return Promise.resolve()
    }

    try {
      const { data: discountCodes } = await this.$api.get(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/discount-codes`
      )
      const key = getStoreFormKey({ orgSlug, formSlug, type })
      commit('SET_DISCOUNT_CODES', { discountCodes, orgSlug, formSlug })
      commit('SET_DISCOUNT_CODES_STATUS', { status: Status.SUCCESS })
      commit(
        'forms/SET_FORM_DISCOUNT_CODES',
        { key, discountCodes: discountCodes || [] },
        { root: true }
      )
    } catch (error) {
      commit('SET_DISCOUNT_CODES_STATUS', { status: Status.ERROR })
      throw error
    }
  },

  createDiscountCode(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      discountCode
    }: { orgSlug: string; formSlug: string; type: FormType; discountCode: DiscountCode }
  ) {
    commit('SET_DISCOUNT_CODES_STATUS', { status: Status.LOADING })

    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/discount-codes`, discountCode)
      .then((response) => {
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.SUCCESS })
        commit('ADD_DISCOUNT_CODE', { discountCode: response.data })
      })
      .catch((error) => {
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.ERROR })
        throw error
      })
  },
  updateDiscountCode(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      discountCode
    }: { orgSlug: string; formSlug: string; type: FormType; discountCode: DiscountCode }
  ) {
    commit('SET_DISCOUNT_CODES_STATUS', { status: Status.LOADING })

    return this.$api
      .put(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/discount-codes/${discountCode.id}`,
        discountCode
      )
      .then((response) => {
        commit('SET_DISCOUNT_CODE', { discountCode: response.data })
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.ERROR })
        throw error
      })
  },
  deleteDiscountCode(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    commit('SET_DISCOUNT_CODES_STATUS', { status: Status.LOADING })

    return this.$api
      .delete(`/organizations/${orgSlug}/forms/${type}/${formSlug}/discount-codes/${id}`)
      .then(() => {
        commit('DELETE_DISCOUNT_CODE', { id })
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.ERROR })
        throw error
      })
  },
  enableDiscountCode(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    commit('SET_DISCOUNT_CODES_STATUS', { status: Status.LOADING })

    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/discount-codes/${id}/enable`)
      .then(() => {
        commit('ENABLE_DISCOUNT_CODE', { id })
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.ERROR })
        throw error
      })
  },
  disableDiscountCode(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    commit('SET_DISCOUNT_CODES_STATUS', { status: Status.LOADING })

    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/discount-codes/${id}/disable`)
      .then(() => {
        commit('DISABLE_DISCOUNT_CODE', { id })
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_DISCOUNT_CODES_STATUS', { status: Status.ERROR })
        throw error
      })
  }
}
