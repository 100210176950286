import { orgState, stepState } from '@/helpers/enums'

export const isNotAnEuropeanUnionCountry = (state) => {
  const euCountriesValueList = state.references.europeanUnionCountry.map((country) =>
    country.value.toString()
  )
  return !euCountriesValueList.some(
    (value) => value === state.representative.nationality.toString()
  )
}

export const isStepImagesValid = (state) => (step) =>
  Object.values(state.imgErrors[step]).every((value) => value === null)

export const isCompleteAndNotInModification = (state) =>
  state.organizationState === orgState.VALID && !state.isCurrentlyModify

export const areAllStepsComplete = (state) => {
  return (
    state.bankInformation.state === stepState.COMPLETE &&
    state.legalInformation.state === stepState.COMPLETE &&
    state.representative.state === stepState.COMPLETE
  )
}
export const isReadyToSend = (state) => {
  return (
    (state.organizationState === orgState.INITIAL && areAllStepsComplete(state)) ||
    (state.organizationState === orgState.INCOMPLETE && areAllStepsComplete(state)) ||
    (state.organizationState === orgState.VALID &&
      state.isCurrentlyModify &&
      areAllStepsComplete(state))
  )
}
