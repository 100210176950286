import { HeadersExports } from '@/components/participants/list/types/participants'

const getContentDisposition = (contentDisposition: string): object => {
  return contentDisposition.split(';').reduce((formattedValues, item) => {
    const [name, value] = item.split('=')
    if (!name || !value) return formattedValues
    formattedValues[name.trim()] = value.trim()
    return formattedValues
  }, {})
}

export default (headers: HeadersExports): string => {
  if (!headers?.['content-disposition']) throw new Error('Missing content-disposition header')

  const contentDispositionFormatted = getContentDisposition(headers['content-disposition'])

  if (!contentDispositionFormatted['filename']) {
    throw new Error('Missing filename in content-disposition header')
  }

  if (!contentDispositionFormatted['filename*']) return contentDispositionFormatted['filename']

  return decodeURI(contentDispositionFormatted['filename*'].replace(/(UTF-8'')|(utf-8'')/, ''))
}
