import { get } from 'lodash-es'

/** Transform the error into a more user friendly message. */
export default function formatError(error, i18n) {
  if (error.message === 'Network Error') {
    return i18n.t('errors.networkErrorBody')
  }

  if (error.customMessage) {
    return error.customMessage
  }

  // when API return an error set it in the notification body
  if (error.response?.data?.errors?.[0]?.message) {
    return get(error, 'response.data.errors[0].message')
  }
  const statusCode = error?.response?.status

  if (statusCode) {
    return i18n.t('errors.statusCodeErrorBody', [statusCode])
  }

  return i18n.t('errors.unknownErrorBody')
}
