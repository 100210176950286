
import { HaButton } from '@ha/components'

/** Sidebar menu item. */
export default {
  name: 'LinkItem',
  components: {
    HaButton
  },
  props: {
    text: String,
    to: [Object, String],
    icon: Array,
    isActive: Boolean,
    isMain: Boolean,
    isSub: Boolean,
    isSubToggle: Boolean
  }
}
