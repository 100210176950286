import { enum as enums } from '@ha/helpers'

// Should match the `types` provided by the API
export const OrganizationStatus = {
  Association1901: 'Association1901',
  Association1901Rup: 'Association1901Rup',
  Association1901Rig: 'Association1901Rig',
  FondationSousEgide: 'FondationSousEgide',
  FondationRup: 'FondationRup',
  FondDotation: 'FondDotation',
  FondationScientifique: 'FondationScientifique',
  FondationPartenariale: 'FondationPartenariale',
  FondationUniversitaire: 'FondationUniversitaire',
  FondationHospitaliere: 'FondationHospitaliere',
  Association1905: 'Association1905',
  Association1905Rup: 'Association1905Rup',
  Entreprise: 'Entreprise',
  Cooperative: 'Cooperative',
  Etablissement: 'Etablissement',
  Association1908: 'Association1908',
  Association1908Rig: 'Association1908Rig',
  Association1908Rup: 'Association1908Rup '
}

// Possible durations for the form date
export const Durations = {
  NONE: 'none',
  ONE_DAY: 'one-day',
  SEVERAL_DAYS: 'several-days'
}

export const TierState = {
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
  DELETED: 'Deleted'
}

export const ExtraOptionState = {
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
  DELETED: 'Deleted'
}

export const DiscountCodesStates = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  DELETED: 'Deleted'
}

export const Status = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  LOADING: 'LOADING'
}

export const FormState = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  DRAFT: 'Draft',
  DISABLED: 'Disabled',
  DELETED: 'Deleted'
}

/**
 * Edition modes.
 */
export const EditionMode = {
  CREATE: 'CREATE',
  /** Editing a tier which has not been bought yet. */
  EDIT: 'EDIT',
  /** Editing a tier that has been bought. */
  LIMITED: 'LIMITED',
  /** Editing the duplicated version of a tier. */
  DUPLICATED: 'DUPLICATED'
}

/**
 * Payment cashOut modes (To association)
 */
export const CashOutStatus = {
  MONEY_IN: 'MoneyIn',
  CANT_TRANSFER_RECEIVE_FULL: 'CantTransferReceiverFull',
  TRANSFERED: 'Transfered',
  TRANSFER_IN_PROGRESS: 'TransferInProgress',
  REFUNDED: 'Refunded',
  REFUNDING: 'Refunding',
  WAITING_FOR_CASHOUT_CONFIRMATION: 'WaitingForCashOutConfirmation',
  CASHED_OUT: 'CashedOut',
  UNKNOWN: 'Unknown',
  CONTESTED: 'Contested'
}

/**
 * Payment cashIn modes (From Contributors)
 */
export const CashInStatus = {
  PENDING: 'Pending',
  AUTHORIZED: 'Authorized',
  REFUSED: 'Refused',
  UNKNOWN: 'Unknown',
  REGISTERED: 'Registered',
  ERROR: 'Error',
  REFUNDED: 'Refunded',
  REFUNDING: 'Refunding',
  WAITING: 'Waiting',
  CANCELED: 'Canceled',
  CONTESTED: 'Contested',
  WAITING_BANK_VALIDATION: 'WaitingBankValidation',
  WAITING_BANK_WITHDRAW: 'WaitingBankWithdraw',
  WAITING_AUTHENTICATION: 'waitingAuthentication'
}

export const PaymentStatus = {
  PROCESSED: 'Processed',
  REGISTERED: 'Registered',
  CANCELED: 'Canceled'
}

export const errorDocumentStatuses = [
  enums.ComplianceDocumentStatus.REJECTED,
  enums.ComplianceDocumentStatus.UNREADABLE,
  enums.ComplianceDocumentStatus.EXPIRED,
  enums.ComplianceDocumentStatus.WRONGTYPE,
  enums.ComplianceDocumentStatus.WRONGHOLDER,
  enums.ComplianceDocumentStatus.WAITINGOTHERDOCUMENT,
  enums.ComplianceDocumentStatus.DUPLICATE
]

/**
 * Payment means modes
 */
export const PaymentMeans = {
  NONE: 'None',
  CARD: 'Card',
  SEPA: 'Sepa',
  CHECK: 'Check'
}

/**
 * export file formats
 */
export const FileFormat = {
  EXCEL: 'excel',
  CSV: 'csv'
}

/**
 * payment type describes more accuratly a Registered state
 */
export const PaymentType = {
  OFFLINE: 'Offline',
  CREDIT: 'Credit',
  DEBIT: 'Debit'
}

/**
 * Defines state keyword, matching design system
 */
export const PaletteState = {
  TEXT: 'text',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger'
}

/**
 * Role in organization
 */
export const Role = {
  ORGANIZATION_ADMIN: 'OrganizationAdmin',
  FORM_ADMIN: 'FormAdmin'
}

export const RealApiToUrlTypes = {
  Event: 'evenements',
  Membership: 'adhesions',
  Donation: 'formulaires',
  CrowdFunding: 'collectes',
  PaymentForm: 'ventes',
  Shop: 'boutiques'
}

export const RealUrlToApiTypes = {
  evenements: 'Event',
  adhesions: 'Membership',
  formulaires: 'Donation',
  collectes: 'CrowdFunding',
  ventes: 'PaymentForm',
  boutiques: 'Shop'
}

//! DEPRECATED for new integration prefer => RealApiToUrlTypes || RealUrlToApiTypes
/* @REFACTO: The aim is to use only the formType returned by the server (e.g. 'CrowdFunding' in UrlToFormType in @ha/helpers).
We have to keep UrlToApiTypes until we make the changes. */
export const UrlToApiTypes = {
  evenements: 'EVENT',
  adhesions: 'MEMBERSHIP',
  formulaires: 'DONATION',
  collectes: 'CROWDFUNDING',
  ventes: 'PAYMENTFORM',
  boutiques: 'SHOP'
}

//! DEPRECATED for new integration prefer => RealApiToUrlTypes || RealUrlToApiTypes
export const ApiTypesToUrl = {
  EVENT: 'evenements',
  MEMBERSHIP: 'adhesions',
  CROWDFUNDING: 'collectes',
  PAYMENTFORM: 'ventes',
  DONATION: 'formulaires',
  SHOP: 'boutiques'
}

//! DEPRECATED for new integration prefer => RealApiToUrlTypes || RealUrlToApiTypes
export const CampaignType = {
  FUNDRAISING: 'FUNDRAISING',
  SALE: 'SALE',
  EVENT: 'EVENT',
  MEMBERSHIP: 'MEMBERSHIP',
  CROWDFUNDING: 'CROWDFUNDING',
  PAYMENTFORM: 'PAYMENTFORM',
  DONATION: 'DONATION',
  SHOP: 'SHOP',
  UNKNOWN: 'UNKNOWN'
}

export const FrequencyTypes = {
  SINGLE: 'Single',
  MONTHLY: 'Monthly',
  INSTALLMENT: 'Installment'
}

export const PriceCategories = {
  MONTHLY: 'monthly',
  INSTALLMENT: 'installment',
  FIXED: 'fixed',
  PWYW: 'pwyw', // "Pay what you want"
  FREE: 'free',
  PWYW_MULTIPLE: 'pwywMultiple',
  FIXED_MULTIPLE: 'fixedMultiple'
}

export const OptionCategories = {
  FIXED: 'fixed',
  FREE: 'free'
}

export const DiscountCodesActions = {
  POST_PROMOCODE: 'POST_PROMOCODE',
  PUT_PROMOCODE: 'PUT_PROMOCODE',
  DELETE_PROMOCODE: 'DELETE_PROMOCODE',
  ACTIVATE_PROMOCODE: 'ACTIVATE_PROMOCODE',
  DEACTIVATE_PROMOCODE: 'DEACTIVATE_PROMOCODE'
}

export const AttendeesInformationsActions = {
  POST_ATTENDEES_INFORMATION: 'POST_ATTENDEES_INFORMATION',
  PUT_ATTENDEES_INFORMATION: 'PUT_ATTENDEES_INFORMATION',
  DELETE_ATTENDEES_INFORMATION: 'DELETE_ATTENDEES_INFORMATION',
  ORDER_ATTENDEES_INFORMATIONS: 'ORDER_ATTENDEES_INFORMATIONS',
  TIERS: 'PRICES',
  OPTIONS: 'OPTIONS'
}

export const PricingGroupActions = {
  SORT: 'SORT',
  POST_TIER: 'POST_TIER',
  PUT_TIER: 'PUT_TIER',
  DUPLICATE_TIER: 'DUPLICATE_TIER',
  DELETE_TIER: 'DELETE_TIER',
  POST_OPTION: 'POST_OPTION',
  PUT_OPTION: 'PUT_OPTION',
  DUPLICATE_OPTION: 'DUPLICATE_OPTION',
  DELETE_OPTION: 'DELETE_OPTION',
  VISIBILITY: 'VISIBILITY'
}

export const PaymentProviderTypes = {
  LEMONWAY: 'Lemonway',
  STRIPE: 'Stripe'
}

export const ComplianceRepresentativeState = {
  UNKNOWN: 'Unknown',
  INIT: 'Init',
  VERIFICATION_PENDING: 'VerificationPending',
  VERIFICATION_FAILED: 'VerificationFailed',
  VERIFIED: 'Verified',
  ERROR: 'Error'
}

export const AvailablePriceCategories = [
  {
    icon: 'tag',
    type: PriceCategories.FIXED,
    frequency: FrequencyTypes.SINGLE
  },
  {
    icon: 'calendar-day',
    type: PriceCategories.FIXED_MULTIPLE,
    frequency: FrequencyTypes.MONTHLY
  },
  {
    icon: 'calendar-day',
    type: PriceCategories.MONTHLY,
    frequency: FrequencyTypes.MONTHLY
  },
  {
    icon: 'redo',
    type: PriceCategories.INSTALLMENT,
    frequency: FrequencyTypes.INSTALLMENT
  },
  {
    icon: 'sliders-h',
    type: PriceCategories.PWYW,
    frequency: FrequencyTypes.SINGLE
  },
  {
    icon: 'star',
    type: PriceCategories.PWYW_MULTIPLE,
    frequency: FrequencyTypes.MONTHLY
  },
  {
    icon: 'star',
    type: PriceCategories.FREE,
    frequency: FrequencyTypes.SINGLE
  }
]

export const AvailableExtraOptionCategories = [
  {
    icon: 'tag',
    type: OptionCategories.FIXED
  },
  {
    icon: 'star',
    type: OptionCategories.FREE
  }
]

/**
 * Organization cashOut status
 */
export const OrganizationCashOutStatus = {
  CLOSED: 'Closed',
  BLOCKED: 'Blocked',
  CANCELED: 'Canceled',
  DONE: 'Done',
  ERROR: 'Error',
  PENDING: 'Pending',
  PROGRESS: 'Progress',
  REFUSED: 'Refused',
  REVERTED: 'Reverted',
  REVERTED_ERROR: 'RevertedError',
  REVERTING: 'Reverting',
  UNKNOWN: 'Unknown',
  WAITING_VALIDATION: 'WaitingValidation'
}

export const WalletState = {
  ENABLED: 'Enabled',
  LIMITED: 'Limited',
  RESTRICTED_SOON: 'RestrictedSoon',
  RESTRICTED: 'Restricted'
}

export const WidgetType = {
  BUTTON: 'button',
  FORM: 'form',
  VIGNETTE: 'vignette',
  COUNTER: 'counter'
}

export const stepKeys = ['informations', 'tiers', 'customFields', 'customization']

export const stepState = {
  INCOMPLETE: 'Incomplete',
  COMPLETE: 'Complete'
}

export const orgState = {
  INITIAL: 'Initial',
  WAITING: 'Waiting',
  INCOMPLETE: 'Incomplete',
  VALID: 'Valid'
}

export const identityDocuments = {
  PASSPORT: 'passeport',
  ID_CARD: 'idCard'
}
