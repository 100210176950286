import { DataRouteTracking, NuxtRoute } from '@/helpers/tsenums'

const trackingFormType = {
  evenements: 'Billetterie',
  adhesions: 'Adhesion',
  formulaires: 'Don',
  collectes: 'Crowdfunding',
  ventes: 'Vente',
  boutiques: 'Boutique'
}

/**
 * Get tracking data depending on the application's route
 */
const getTrackingRouteData = (route: NuxtRoute): DataRouteTracking => {
  const { params, path } = route
  const {
    organization: organization_slug = '',
    type: form_type = '',
    form: form_slug = '',
    step = ''
  } = params
  const data: DataRouteTracking = {
    rubrik: 'BO',
    context: 'Association',
    category: '',
    title: ''
  }
  const formattedFormType = trackingFormType[form_type || '']
  if (organization_slug) data.organization_slug = organization_slug
  if (form_type) data.form_type = form_type
  if (form_slug) data.form_slug = form_slug

  /**
   * Source: https://docs.google.com/spreadsheets/d/194FHx6xdPvaIBIVwukljO2nw_mx-8bIGkRgoGq-mfKI/edit#gid=1948717279
   */
  switch (true) {
    case /(.*)\/accueil\/bienvenue/.test(path):
    case /(.*)\/accueil/.test(path):
      return {
        ...data,
        category: 'Home Asso',
        title: 'Accueil'
      }
    case /(.*)\/authentification$/.test(path):
      return {
        ...data,
        category: 'Paramètres',
        title: 'Vérification de mon association'
      }
    case /(.*)\/integrations$/.test(path):
      return {
        ...data,
        category: 'Paramètres',
        title: 'Intégrations et API'
      }
    case /(.*)\/mon-compte\/parametres/.test(path):
      return {
        ...data,
        category: 'Paramètres',
        title: "Droit d'accès"
      }
    case /(.*)\/mon-compte\/versements/.test(path):
      return {
        ...data,
        category: 'Versements',
        title: 'Mes versements'
      }
    case /(.*)\/paiements/.test(path):
      return {
        ...data,
        category: 'Suivi de campagne',
        title: 'Suivi de paiement'
      }
    case /(.*)\/collectes$/.test(path):
    case /(.*)\/boutiques$/.test(path):
    case /(.*)\/evenements$/.test(path):
    case /(.*)\/adhesions$/.test(path):
    case /(.*)\/formulaires$/.test(path):
    case /(.*)\/ventes$/.test(path):
      return {
        ...data,
        category: 'Suivi de campagne',
        title: `Accueil - ${formattedFormType}`
      }
    case /(.*)\/creation$/.test(path):
    case /(.*)\/creation\/bienvenue$/.test(path):
    case /(.*)\/creation\/(.*)/.test(path):
      return {
        ...data,
        category: 'Création / Edition campagne',
        title: `Création étape ${step || 1} sur 4 - ${formattedFormType}`
      }
    case /(.*)\/edition\/(.*)/.test(path):
      return {
        ...data,
        category: 'Création / Edition campagne',
        title: `Edition étape ${step || 1} sur 4 - ${formattedFormType}`
      }
    case /(.*)\/diffusion$/.test(path):
      return {
        ...data,
        category: 'Suivi de campagne',
        title: `Diffusion - ${formattedFormType}`
      }
    case /(.*)\/statistiques$/.test(path):
      return {
        ...data,
        category: 'Suivi de campagne',
        title: `Statistiques - ${formattedFormType}`
      }
    case /(.*)\/administrateurs$/.test(path):
      return {
        ...data,
        category: 'Suivi de campagne',
        title: `Administateurs - ${formattedFormType}`
      }
    default:
      return data
  }
}

export default getTrackingRouteData
