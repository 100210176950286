
import { HaLoader } from '@ha/components'

export default {
  name: 'PageLoader',
  components: { HaLoader },
  props: {
    text: String
  }
}
