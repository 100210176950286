import { computed } from 'vue'
import { useStore } from '@nuxtjs/composition-api'
import { haMoment, enum as enums } from '@ha/helpers'
import useStoreData from '@/composables/useStoreData'

export default () => {
  const store = useStore()
  const { organization, user, formTypes, provider } = useStoreData()

  const hasFormTypeCreated = formType => formTypes.value.includes(formType)

  const paymentsCount = computed(() => store.state.payments.totalPaymentsCount || 0)
  const dataLayer = computed(() => {
    const {
      authenticationState,
      category,
      contact,
      firstFormCreationDate,
      firstPaymentDate,
      id,
      lastCashoutDate,
      lastFormCreationDate,
      lastFormCreationType,
      lastOrderDate,
      lastPaymentDate,
      name,
      organizationSlug,
      referrer,
      signUpDate,
      url,
      zipCode,
      fiscalReceiptEligibility,
      fiscalReceiptIssuanceEnabled
    } = organization.value
    const { firstName, lastName, lastSignInDate, email } = user.value

    const threeMonthsAgo = haMoment().subtract(3, 'month')
    const twelveMonthsAgo = haMoment().subtract(12, 'month')
    const nineMonthsAgo = haMoment().subtract(9, 'month')
    const hadPaymentInTheLastThreeMonths = lastPaymentDate
      ? haMoment(lastPaymentDate).isAfter(threeMonthsAgo)
      : false
    const active = lastPaymentDate ? haMoment(lastPaymentDate).isAfter(twelveMonthsAgo) : false
    const engaged = paymentsCount.value > 10
    const churned = lastPaymentDate
      ? haMoment(lastPaymentDate).isSameOrBefore(twelveMonthsAgo)
      : false
    const soonChurned = lastPaymentDate
      ? haMoment(lastPaymentDate).isSameOrBefore(nineMonthsAgo)
      : false
    const hasFormCrowdfundingCreated = hasFormTypeCreated(enums.FormType.CROWDFUNDING)
    const hasFormDonationCreated = hasFormTypeCreated(enums.FormType.DONATION)
    const hasFormEvenCreated = hasFormTypeCreated(enums.FormType.EVENT)
    const hasFormMembershipCreated = hasFormTypeCreated(enums.FormType.MEMBERSHIP)
    const hasFormPaymentFormCreated = hasFormTypeCreated(enums.FormType.PAYMENTFORM)
    const hasFormShopCreated = hasFormTypeCreated(enums.FormType.SHOP)
    const previousData = window.appCues?.organismData || {}

    return {
      ...previousData,
      authenticationState,
      category,
      contact,
      firstFormCreationDate,
      firstPaymentDate,
      id,
      lastCashoutDate,
      lastFormCreationDate,
      lastFormCreationType,
      lastOrderDate,
      lastPaymentDate,
      name,
      organizationSlug,
      referrer,
      signUpDate,
      url,
      hadPaymentInTheLastThreeMonths,
      provider: provider.value,
      active,
      engaged,
      churned,
      soonChurned,
      hasFormCrowdfundingCreated,
      hasFormDonationCreated,
      hasFormEvenCreated,
      hasFormMembershipCreated,
      hasFormPaymentFormCreated,
      hasFormShopCreated,
      userEmail: email,
      zipCode,
      fiscalReceiptEligibility,
      fiscalReceiptIssuanceEnabled,
      firstName,
      lastName,
      lastSignInDate
    }
  })

  const sendDataLayer = () => {
    window.appCues = {
      ...window.appCues,
      organismData: {
        ...dataLayer.value
      }
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      organismData: {
        ...dataLayer.value
      }
    })

    if ('init' in window.appCues) {
      window.appCues.init()
    }

    const createdAt = haMoment(organization.value.signUpDate).unix()

    window.wootricSettings = {
      account_token: 'NPS-ad4ebf8d',
      email: user.value?.email,
      external_id: user.value?.userId,
      created_at: createdAt
    }
  }

  return { dataLayer, sendDataLayer }
}
