import useConfigEnv from '@/composables/useConfigEnv'

export default () => {
  const {
    FEATURE_RF_SEND_CONFIGURATION,
    FEATURE_DELEGATION_LETTER,
    FEATURE_NEW_TIERS,
    FEATURE_PAYMENT_PARAMETERS,
    FEATURE_MODAL_TEMPLATE_MEMBERSHIP,
    FEATURE_MODAL_TWO_FA_TO_USERSPACE
  } = useConfigEnv()

  const getAllowedFormTypes = (configEnv) => configEnv.split(',') || []

  const getFlippingRFSendConfiguration = () => FEATURE_RF_SEND_CONFIGURATION
  const getFlippingDelegationLetter = () => !!FEATURE_DELEGATION_LETTER

  const getFlippingNewTiers = (localFormType) => {
    if (!FEATURE_NEW_TIERS) return false
    return getAllowedFormTypes(FEATURE_NEW_TIERS).includes(localFormType)
  }

  const getFlippingPaymentParameters = (localFormType) => {
    if (!FEATURE_PAYMENT_PARAMETERS) return false
    return getAllowedFormTypes(FEATURE_PAYMENT_PARAMETERS).includes(localFormType)
  }

  const getFlippingModalTemplateMembership = () => FEATURE_MODAL_TEMPLATE_MEMBERSHIP

  const getFlippingModalTwoFAToUserspace = () => FEATURE_MODAL_TWO_FA_TO_USERSPACE

  return {
    getFlippingRFSendConfiguration,
    getFlippingNewTiers,
    getFlippingPaymentParameters,
    getFlippingDelegationLetter,
    getFlippingModalTemplateMembership,
    getFlippingModalTwoFAToUserspace
  }
}
