import { Tracking } from '@ha/helpers'
import { useContext } from '@nuxtjs/composition-api'
import useStoreData from '@/composables/useStoreData'

let tracking = null

export default () => {
  const { $config } = useContext()
  const { user, organization } = useStoreData()

  /* Because we don't have access to process.env.SEGMENT_KEY in client side,
  we have to instanciate it like this with $config.SEGMENT_KEY */
  if (!tracking) {
    tracking = new Tracking({
      segmentKey: $config.SEGMENT_KEY,
      enabled: $config.SEGMENT_ENABLED
    })
  }

  const identifyUser = async () => {
    try {
      const { userId, email, firstName, lastName, lastSignInDate } = user.value
      const { lastPaymentDate } = organization.value
      const segmentData = {
        userId,
        email,
        firstName,
        lastName,
        lastSignInDate,
        lastPaymentReceived: lastPaymentDate,
        isReceivedPayment: !!lastPaymentDate
      }
      await tracking.identify(userId, segmentData)
    } catch (error) {
      console.error('Error on identify user', error)
    }
  }

  return {
    tracking,
    identifyUser
  }
}
