import { enum as enums } from '@ha/helpers'

export default {
  withoutCampaigns: {
    tools: {
      [enums.FormType.EVENT]: 'BoAsso_HomeWithoutCampaigns_Tools_Form',
      [enums.FormType.MEMBERSHIP]: 'BoAsso_HomeWithoutCampaigns_Tools_Membership',
      [enums.FormType.DONATION]: 'BoAsso_HomeWithoutCampaigns_Tools_Donation',
      [enums.FormType.PAYMENTFORM]: 'BoAsso_HomeWithoutCampaigns_Tools_PaymentForm',
      [enums.FormType.CROWDFUNDING]: 'BoAsso_HomeWithoutCampaigns_Tools_CrowdFunding',
      example: 'BoAsso_HomeWithoutCampaigns_Tools_Example',
      help: 'BoAsso_HomeWithoutCampaigns_Tools_Help'
    },
    settings: {
      orgPage: 'BoAsso_HomeWithoutCampaigns_Settings_OrgPage',
      iban: 'BoAsso_HomeWithoutCampaigns_Settings_Iban'
    }
  },
  withCampaigns: {
    account: {
      cashout: 'BoAsso_HomeWithCampaigns_Account_Cashout',
      payments: 'BoAsso_HomeWithCampaigns_Account_Payments',
      newCampaign: 'BoAsso_HomeWithCampaigns_Account_NewCampaign',
      create: {
        [enums.FormType.EVENT]: 'BoAsso_HomeWithCampaigns_Account_CreateForm',
        [enums.FormType.MEMBERSHIP]: 'BoAsso_HomeWithCampaigns_Account_CreateMembership',
        [enums.FormType.DONATION]: 'BoAsso_HomeWithCampaigns_Account_CreateDonation',
        [enums.FormType.PAYMENTFORM]: 'BoAsso_HomeWithCampaigns_Account_CreatePaymentForm',
        [enums.FormType.CROWDFUNDING]: 'BoAsso_HomeWithCampaigns_Account_CreateCrowdFunding'
      }
    },
    campaigns: {
      newCampaign: 'BoAsso_HomeWithCampaigns_Campaigns_NewCampaign',
      create: {
        [enums.FormType.EVENT]: 'BoAsso_HomeWithCampaigns_Campaigns_CreateForm',
        [enums.FormType.MEMBERSHIP]: 'BoAsso_HomeWithCampaigns_Campaigns_CreateMembership',
        [enums.FormType.DONATION]: 'BoAsso_HomeWithCampaigns_Campaigns_CreateDonation',
        [enums.FormType.PAYMENTFORM]: 'BoAsso_HomeWithCampaigns_Campaigns_CreatePaymentForm',
        [enums.FormType.CROWDFUNDING]: 'BoAsso_HomeWithCampaigns_Campaigns_CreateCrowdFunding'
      },
      details: 'BoAsso_HomeWithCampaigns_Campaigns_Details'
    }
  }
}
