import { useContext } from '@nuxtjs/composition-api'

export interface ConfigEnv {
  SEGMENT_KEY: string
  SEGMENT_ENABLED: boolean
  DD_RUM_APPLICATION_ID: string
  DD_RUM_CLIENT_TOKEN: string
  DD_SITE: string
  DD_SERVICE: string
  DD_ENV: string
  DD_RUM_SESSION_SAMPLE_RATE: string
  DD_RUM_SESSION_REPLAY_SAMPLE_RATE: string
  FEATURE_HIDE_WELCOME_MODAL: boolean
  FEATURE_MOBILE_PROMOTION: boolean
  FEATURE_RF_SEND_CONFIGURATION: boolean
  FEATURE_DELEGATION_LETTER: boolean
  FEATURE_NEW_TIERS: string
  FEATURE_PAYMENT_PARAMETERS: string
  FEATURE_MODAL_TEMPLATE_MEMBERSHIP: boolean
  FEATURE_MODAL_TWO_FA_TO_USERSPACE: boolean
  NUXT_ENV_OLD_BO_URL: string
  NUXT_ENV_BASE_URL: string
  NUXT_ENV_BO_URL: string
  NUXT_ENV_BASE_API_BO: string
  NUXT_ENV_AUTH_URL_BO: string
  NUXT_ENV_AUTH_FRONT: string
  NUXT_ENV_SF_BASE_URL: string
  NUXT_ENV_SF_BASE_LIVE_AGENT_URL: string
  NUXT_ENV_SF_BASE_LIVE_AGENT_CONTENT_URL: string
  NUXT_ENV_SF_INSTANCE_ID: string
  NUXT_ENV_SF_SUPPORT_LIVE_AGENT_URL: string
  NUXT_ENV_SF_ONBOARDING_LIVE_AGENT_URL: string
  NUXT_ENV_SF_SUPPORT_BUTTON_ID: string
  NUXT_ENV_SF_ONBOARDING_BUTTON_ID: string
}

export default (): ConfigEnv => {
  const { $config } = useContext()

  const {
    SEGMENT_KEY,
    SEGMENT_ENABLED,
    DD_RUM_APPLICATION_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_SITE,
    DD_SERVICE,
    DD_ENV,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    FEATURE_HIDE_WELCOME_MODAL,
    FEATURE_MOBILE_PROMOTION,
    FEATURE_RF_SEND_CONFIGURATION,
    FEATURE_DELEGATION_LETTER,
    FEATURE_NEW_TIERS,
    FEATURE_PAYMENT_PARAMETERS,
    FEATURE_MODAL_TEMPLATE_MEMBERSHIP,
    FEATURE_MODAL_TWO_FA_TO_USERSPACE,
    NUXT_ENV_OLD_BO_URL,
    NUXT_ENV_BASE_URL,
    NUXT_ENV_BO_URL,
    NUXT_ENV_BASE_API_BO,
    NUXT_ENV_AUTH_URL_BO,
    NUXT_ENV_AUTH_FRONT,
    NUXT_ENV_SF_BASE_URL,
    NUXT_ENV_SF_BASE_LIVE_AGENT_URL,
    NUXT_ENV_SF_BASE_LIVE_AGENT_CONTENT_URL,
    NUXT_ENV_SF_INSTANCE_ID,
    NUXT_ENV_SF_SUPPORT_LIVE_AGENT_URL,
    NUXT_ENV_SF_ONBOARDING_LIVE_AGENT_URL,
    NUXT_ENV_SF_SUPPORT_BUTTON_ID,
    NUXT_ENV_SF_ONBOARDING_BUTTON_ID
  } = $config

  return {
    SEGMENT_KEY,
    SEGMENT_ENABLED,
    DD_RUM_APPLICATION_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_SITE,
    DD_SERVICE,
    DD_ENV,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    FEATURE_HIDE_WELCOME_MODAL,
    FEATURE_MOBILE_PROMOTION,
    FEATURE_RF_SEND_CONFIGURATION,
    FEATURE_DELEGATION_LETTER,
    FEATURE_NEW_TIERS,
    FEATURE_PAYMENT_PARAMETERS,
    FEATURE_MODAL_TEMPLATE_MEMBERSHIP,
    FEATURE_MODAL_TWO_FA_TO_USERSPACE,
    NUXT_ENV_OLD_BO_URL,
    NUXT_ENV_BASE_URL,
    NUXT_ENV_BO_URL,
    NUXT_ENV_BASE_API_BO,
    NUXT_ENV_AUTH_URL_BO,
    NUXT_ENV_AUTH_FRONT,
    NUXT_ENV_SF_BASE_URL,
    NUXT_ENV_SF_BASE_LIVE_AGENT_URL,
    NUXT_ENV_SF_BASE_LIVE_AGENT_CONTENT_URL,
    NUXT_ENV_SF_INSTANCE_ID,
    NUXT_ENV_SF_SUPPORT_LIVE_AGENT_URL,
    NUXT_ENV_SF_ONBOARDING_LIVE_AGENT_URL,
    NUXT_ENV_SF_SUPPORT_BUTTON_ID,
    NUXT_ENV_SF_ONBOARDING_BUTTON_ID
  }
}
