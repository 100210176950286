import { DateOperator } from '@ha/helpers'
import { ParticipantsFilters } from '@/components/participants/list/types/participants'

export default (apiUrl: string, filters: ParticipantsFilters): string => {
  const fakeUrl = 'http://www.fake.com'
  const url = new URL(`${fakeUrl}/${apiUrl}`)

  url.searchParams.set('withDetails', 'true')

  if (filters?.query) {
    url.searchParams.set('userSearchKey', filters.query)
  }

  if (filters?.startDate && DateOperator.isValid(filters.startDate)) {
    url.searchParams.set('from', new DateOperator(filters.startDate).format('YYYY-MM-DD'))
  }

  if (filters?.endDate && DateOperator.isValid(filters.endDate)) {
    url.searchParams.set('to', new DateOperator(filters.endDate).endOf('day').format())
  }

  filters?.tierIds?.forEach(tierId => {
    url.searchParams.append('tierIds', tierId)
  })

  filters?.itemIds?.forEach(itemId => {
    url.searchParams.append('itemIds', itemId)
  })

  if (filters?.pageSize) {
    url.searchParams.set('pageSize', String(filters.pageSize))
  } else {
    url.searchParams.set('retrieveAll', 'true')
  }

  if (filters?.continuationToken) {
    url.searchParams.set('continuationToken', filters.continuationToken)
  }

  return url.toString().replace(fakeUrl, '')
}
