
import { onMounted } from 'vue'
import { haMoment, enum as enums, functions } from '@ha/helpers'
import { HaLoader } from '@ha/components'
import useFetchOrganization from '@/composables/useFetchOrganization'

export default {
  name: 'AmountAuthorized',
  components: { HaLoader },
  setup() {
    const { isLoading, fetchCashOut } = useFetchOrganization()

    onMounted(async () => {
      await fetchCashOut()
    })

    return {
      isLoading
    }
  },
  computed: {
    totalPaymentsCount() {
      return this.$store.state.payments.totalPaymentsCount || 0
    },
    alertText() {
      if (this.provider === 'Stripe') {
        if (this.total >= this.limit && this.expirationDate && this.delay > 0) {
          return this.$t('forms.delayForAuthentify', [this.delay])
        }

        if (this.total >= this.limit) {
          return this.$t('forms.cashOutBlocked')
        }
      }

      return this.$t('forms.authentifyToAccessFund')
    },
    total() {
      return this.availableAmount + this.protectedAmount
    },
    limit() {
      const defaultLimit = 250000
      return this.provider === 'Stripe'
        ? this.$store.state.compliance.compliance?.paymentProvider?.organizationThresholdAmount ||
            defaultLimit
        : defaultLimit
    },
    expirationDate() {
      return this.$store.state.compliance.compliance?.wallet?.expirationDate
    },
    delay() {
      if (!this.expirationDate) {
        return false
      }
      const diff = haMoment(new Date(this.expirationDate)).diff(new Date(), 'days', false)
      return diff
    },
    organization() {
      return this.$store.state.organizations.enriched[this.organizationSlug] || {}
    },
    isAuthentified() {
      return this.organization?.authenticationState === enums.ComplianceStatus.VALID
    },
    organizationSlug() {
      return this.$route.params.organization
    },
    availableAmount() {
      return this.$store.state.organizations.cashouts[this.organizationSlug]?.availableAmount || 0
    },
    protectedAmount() {
      return this.$store.state.organizations.cashouts[this.organizationSlug]?.protectedAmount || 0
    },
    hasBeenAuthenticatedOnce() {
      return this.$store.state.compliance.compliance?.wallet?.hasBeenAuthenticatedOnce || false
    },
    allowCollect() {
      return this.$store.state.compliance.compliance?.wallet?.allowCollect || true
    },
    provider() {
      return this.$store.state.compliance.compliance?.paymentProvider?.type
    },
    isStipeOrLW() {
      return this.provider === 'Stripe' || this.provider === 'Lemonway'
    },
    hasTenPayments() {
      return this.totalPaymentsCount > 9
    },
    printAmountAuthorized() {
      return !this.isAuthentified && !this.hasBeenAuthenticatedOnce && this.hasTenPayments
    }
  },
  methods: {
    convertToEuros(price) {
      return functions.convertToEuros(price)
    }
  }
}
