import { Form } from '~/src/helpers/tsenums'
import { Tier } from '@/components/tiers/Tiers.interface'
import { FrontEndPaymentParameters, PaymentParameters } from './paymentParamters.interface'

export const getFrontEndPaymentParameters = (
  { formPaymentTerms }: Form,
  tiers: Tier[]
): FrontEndPaymentParameters => {
  const selectedTierIds = tiers
    .filter(({ id, price }) => {
      return price !== 0 && !formPaymentTerms.excludedTiers.includes(id!)
    })
    .map(({ id }) => id!)

  // JSON trick to break nested references until structuredClone match our coverage requirements https://caniuse.com/?search=structuredClone
  const newRefPaymentTerms = JSON.parse(
    JSON.stringify({
      ...formPaymentTerms,
      selectedTierIds
    })
  )
  return newRefPaymentTerms as FrontEndPaymentParameters
}

export const getBackEndPaymentParameters = (
  paymentParameters: FrontEndPaymentParameters,
  tiers
): any => {
  const { selectedTierIds, frequencies, dayOfLevy } = paymentParameters

  const excludedTiers = tiers.filter(({ id }) => !selectedTierIds.includes(id)).map(({ id }) => id)

  const enabledFrequencies =
    frequencies?.filter((f) => f.enabled).map(({ frequency }) => frequency) || []

  return {
    dayOfLevy,
    enabledFrequencies,
    excludedTiers: excludedTiers
  }
}

export const hasPaymentTierEnabled = (form: Form) => {
  // NB: form.formPaymentTerms.enabled will always be true and cannot be trusted
  if (!form || !form.formPaymentTerms) return false
  const {
    formPaymentTerms: { frequencies }
  } = form

  return frequencies.some(({ enabled }) => enabled)
}

export const disablePaymentTermsForFreeTiers = (tiers: Tier[]): Tier[] => {
  return tiers.map((tier) => {
    if (tier.price === 0) return { ...tier, isExcludedFromFormPaymentTerms: true } // Overwrite API value for free tiers
    return tier
  })
}
