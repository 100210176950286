import { DateOperator } from '@ha/helpers'
/**
 * Get difference between two dates
 */
export const getDatesDiff = (start: string | Date, end: string | Date): number => {
  const startDate = new Date(start)
  const endDate = new Date(end)

  return new DateOperator(endDate).diff(startDate, 'day')
}

export const formatUtcDateStringWithoutTimezone = (dateString: string, outputFormat: string) => {
  // remove the timezones from the date
  try {
    return new DateOperator(dateString.split('T')[0]).format(outputFormat)
  }
  catch (e) {
    return new DateOperator(dateString).format(outputFormat)
  }
}