import { replace, get, set } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import axios from 'axios'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface GouvAddress {
  type: string
  geometry: Geometry
  properties: Properties
}

interface Geometry {
  type: string
  coordinates: number[]
}

interface Properties {
  label: string
  score: number
  housenumber: string
  id: string
  name: string
  postcode: string
  citycode: string
  x: number
  y: number
  city: string
  context: string
  type: string
  importance: number
  street: string
  oldcitycode?: string
  oldcity?: string
  district?: string
}

class State {
  gouvAddresses: GouvAddress[] = []
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  GET_GOUV_ADDRESSES(state, addresses: { addresses: GouvAddress[] }) {
    set(state, 'gouvAddresses', addresses)
  }
}

export const actions = <ActionTree<State, any>>{
  getGouvAddresses({ commit }, query: string) {
    const searchQuery = replace(query, / /gm, '+')

    return axios
      .get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURI(searchQuery)}&limit=5`)
      .then(response => {
        const addresses = get(response, 'data.features') || []
        commit('GET_GOUV_ADDRESSES', addresses)
        return addresses
      })
      .catch(console.error)
  },
  fetchManualAddress(
    _,
    { address, zipCode, city }: { address: string; zipCode: string; city: string }
  ) {
    const queryString = `${address} ${zipCode} ${city}`
    const searchQuery = replace(queryString, / /gm, '+')

    return axios
      .get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURI(searchQuery)}&limit=1`)
      .then(response => {
        const {
          data: { features: addresses }
        } = response
        // Returned coordinates from datagouv API are inverted
        const [longitude, latitude] = addresses[0].geometry.coordinates
        return {
          address: addresses[0].properties.name,
          zipCode: addresses[0].properties.postcode,
          city: addresses[0].properties.city,
          coordinates: { latitude: latitude, longitude: longitude }
        }
      })
      .catch(console.error)
  }
}
