import { size } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status } from '../helpers/tsenums'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface CashOut {
  amounts: Amount
  periodStartDate: Date
  periodEndDate: Date
  params: Param
  capabilities: Capability
  isEligibleToCashOut: boolean
  organizationName: string
}

interface CashOutHistory {
  totalAmount: number // on decimal
  date: Date
  startDate: Date
  endDate: Date
  isAuto: Boolean
  receiptUrl: String
  state: String
  authorEmail: String
}

interface Amount {
  eligibleAmount: number
  waitingAmount: number
  suggestedTipAmounts: number[]
  maxTipAmount: number
}

interface Param {
  isAutomaticCashOutEnabled: boolean
}

interface Capability {
  canUpdateParams: boolean
  hasReceivedPayments: boolean
  isCashOutCompliant: boolean
}

class State {
  entity: CashOut = {
    amounts: {
      eligibleAmount: 0,
      waitingAmount: 0,
      suggestedTipAmounts: [],
      maxTipAmount: 0
    } as Amount,

    periodStartDate: new Date(),
    periodEndDate: new Date(),
    params: {
      isAutomaticCashOutEnabled: false
    } as Param,
    capabilities: {
      canUpdateParams: false,
      hasReceivedPayments: false,
      isCashOutCompliant: false
    } as Capability,
    isEligibleToCashOut: false,
    organizationName: ''
  } as CashOut
  historyEntities: CashOutHistory[] = []
  historyContinuationToken: string = ''
  historyRemaining: number = 0
  status: Status = Status.SUCCESS
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  SET_CASHOUT(state, { cashOut, orgSlug }: { cashOut: CashOut; orgSlug: string }) {
    state.entity = cashOut
  },
  SET_STATUS(state, status: Status) {
    state.status = status
  },
  SET_HISTORY_CONTINUATION_TOKEN(state, token: string) {
    state.historyContinuationToken = token
  },
  SET_HISTORY_REMAINING(state, remaining: number) {
    state.historyRemaining = remaining
  },
  ADD_HISTORY_ENTITIES(state, cashOutHistory: CashOutHistory[]) {
    state.historyEntities.push(...cashOutHistory)
  },
  CLEAR_HISTORY_ENTITIES(state) {
    state.historyEntities = []
  }
}

export const actions = <ActionTree<State, any>>{
  fetchCashOutHistory(
    { commit, state },
    {
      orgSlug,
      pageSize = 10,
      initialFetch
    }: { orgSlug: string; pageSize: number; initialFetch: boolean }
  ) {
    commit('SET_STATUS', Status.LOADING)
    let url = `/organizations/${orgSlug}/cash-out/history?pageSize=${pageSize}`

    if (initialFetch) {
      commit('CLEAR_HISTORY_ENTITIES')
      commit('SET_HISTORY_CONTINUATION_TOKEN', '')
    }

    let continuationToken = state.historyContinuationToken

    if (continuationToken) {
      url = url.concat(`&continuationToken=${continuationToken}`)
    }

    return this.$api
      .get(url)
      .then((response) => {
        const cashOutsHistory = response.data.data
        const token = response.data?.pagination?.continuationToken
        const total = response.data?.pagination?.totalCount

        commit('SET_HISTORY_CONTINUATION_TOKEN', token)

        if (cashOutsHistory) {
          commit('ADD_HISTORY_ENTITIES', cashOutsHistory)
          commit('SET_HISTORY_REMAINING', total - size(state.historyEntities))
        }

        commit('SET_STATUS', Status.SUCCESS)
        return cashOutsHistory
      })
      .catch((error) => {
        commit('SET_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Get cash out entity
   * @param {string} orgSlug
   */
  fetchCashOut({ commit }, { orgSlug }: { orgSlug: string }) {
    commit('SET_STATUS', Status.LOADING)

    return this.$api
      .get(`/organizations/${orgSlug}/cash-out/init/information`)
      .then((response) => {
        commit('SET_CASHOUT', { cashOut: response.data, orgSlug })
        commit('SET_STATUS', Status.SUCCESS)
        return response.data
      })
      .catch((error) => {
        commit('SET_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Initial organization cash out
   * @param {string} orgSlug
   * @param {number} orgTip
   */
  postCashOut({ commit }, { orgSlug, orgTip }: { orgSlug: string; orgTip: number }) {
    commit('SET_STATUS', Status.LOADING)

    const params = { tip: orgTip }
    const url = `/organizations/${orgSlug}/cash-out/init`

    return this.$api
      .post(url, params)
      .then(() => {
        commit('SET_STATUS', Status.SUCCESS)
      })
      .catch((error) => {
        commit('SET_STATUS', Status.ERROR)
        throw error
      })
  },
  /**
   * Update cash out params
   * @param {string} orgSlug
   * @param {boolean} cashOutEnabled
   */
  putCashoutParams(
    { commit },
    { orgSlug, cashOutEnabled }: { orgSlug: string; cashOutEnabled: boolean }
  ) {
    commit('SET_STATUS', Status.LOADING)

    const params = { enableCashoutAuto: cashOutEnabled }
    const url = `/organizations/${orgSlug}/cash-out/params`

    return this.$api
      .put(url, params)
      .then(() => {
        commit('SET_STATUS', Status.SUCCESS)
      })
      .catch((error) => {
        commit('SET_STATUS', Status.ERROR)
        throw error
      })
  }
}
