import { functions } from '@ha/helpers'
import { ConfigEnv } from '@/composables/useConfigEnv'
import { Organizations } from '@/components/users/user.interface'

export default (
  configEnv: ConfigEnv,
  accessToken?: string,
  organizations?: Organizations
): string => {
  if (!configEnv) throw new Error('Missing required param configEnv')

  if (!accessToken) return `${configEnv.NUXT_ENV_BASE_URL}/connexion`

  const { userId } = functions.getAccessTokenInformations(accessToken)
  if (!userId) return `${configEnv.NUXT_ENV_BASE_URL}/connexion`

  const userOrganizations = organizations ? Object.values(organizations) : []

  return userOrganizations[0]?.organizationSlug
    ? `${configEnv.NUXT_ENV_BO_URL}/${userOrganizations[0].organizationSlug}/accueil?redirect=403`
    : `${configEnv.NUXT_ENV_BASE_URL}/utilisateur/profil?redirect=403`
}
