const getJwtInfo = jwt => {
  const encodedPayload = jwt.split('.')[1]
  const decodedPayload = JSON.parse(Buffer.from(encodedPayload, 'base64'))
  const tokenExpiration = decodedPayload.exp
  const utcTimestamp = Date.now().valueOf() / 1000

  return {
    isExpired: !tokenExpiration || utcTimestamp > tokenExpiration,
    isAnonymousToken: !decodedPayload.uid
  }
}

export { getJwtInfo }
