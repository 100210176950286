import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { v4 as uuidv4 } from 'uuid'
import { MutationTree, ActionTree } from 'vuex'
import { DateOperator } from '@ha/helpers'
import { orgState, stepState, identityDocuments } from '@/helpers/enums'
import { formatUtcDateStringWithoutTimezone } from '@/helpers/dateUtils'

import {
  properties
  // @ts-ignore
} from '@/components/verification/helpers/properties.ts'
import { AxiosResponse } from 'axios'

/*
This is the new store for KYC, the old one is compliance.
A new store is needed as API reponses interfaces have changed.
*/

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface BankInformation {
  bic: string
  iban: string
  ribDocumentId?: number | null
  ibanDocument?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  state?: string
}

interface BoardMemberAPI {
  firstName: string
  lastName: string
  positionCode: number
}

interface BoardMember extends BoardMemberAPI {
  id: string
  isRepresentative: boolean
}

interface LegalInformation {
  name: string
  rnaNumber: string
  purpose: string
  address: {
    street: string
    city: string
    zipCode: string
    countryCode: string | number
  }
  statutesDocumentId?: number | null
  joDocumentId?: number | null
  proofOfExistenceDocument?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  statutesDocument?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  state?: string
}

interface Representative {
  firstName: string
  lastName: string
  birthDate: string
  nationality: string | number
  email: string
  positionCode: number
  identityDocumentId?: number | null
  identityBackDocumentId?: number | null
  passportDocumentId?: number | null
  identityBisDocumentId?: number | null
  administratorsDocumentId?: number | null
  proofOfDelegationDocumentId?: number | null
  identityType: string
  identityFront?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  identityBack?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  identityBis?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  passport?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  proofOfAddress?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  proofOfDelegation?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  administrators?: {
    id: number | null
    fileName: string
    publicUrl: string
  }
  state?: string
}

interface References {
  iso3166CountryNationality: [
    {
      value: string
      text: string
    }
  ]
  iso3166Country: [
    {
      value: string
      text: string
    }
  ]
  europeanUnionCountry: [
    {
      value: string
      text: string
    }
  ]
  representativeFunction: [
    {
      value: string
      text: string
    }
  ]
  memberFunction: [
    {
      value: string
      text: string
    }
  ]
}

interface ImgErrors {
  representative: {
    identityFront: null | string
    identityBack: null | string
    identityBis: null | string
    passport: null | string
    proofOfAddress: null | string
    proofOfDelegation: null | string
    administrators: null | string
  }
  legalInformation: {
    proofOfExistenceDocument: null | string
    statutesDocument: null | string
  }
  bankInformation: {
    ibanDocument: null | string
  }
}

type Feature = {
  name: string
  enabled: boolean
}

type State = {
  organizationState: string
  verificationDate: string
  verificationMessage: string
  bankInformation: BankInformation
  boardMembers: BoardMember[]
  isBoardMembersNew: boolean
  legalInformation: LegalInformation
  representative: Representative
  references: References
  imgErrors: ImgErrors
  isCurrentlyModify: boolean
  features: Feature[]
  provider: string
  haApiError: boolean
  hasRaisedFunds: boolean
  hasRestrictedData: boolean
}

export const state = (): State => ({
  organizationState: '',
  verificationDate: '',
  verificationMessage: '',
  bankInformation: {
    iban: '',
    bic: '',
    ribDocumentId: null,
    ibanDocument: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    state: 'Incomplete'
  },
  boardMembers: [
    {
      id: uuidv4(),
      firstName: '',
      lastName: '',
      positionCode: 0,
      isRepresentative: true
    },
    {
      id: uuidv4(),
      firstName: '',
      lastName: '',
      positionCode: 0,
      isRepresentative: false
    }
  ],
  isBoardMembersNew: true,
  legalInformation: {
    name: '',
    rnaNumber: '',
    purpose: '',
    address: {
      street: '',
      city: '',
      zipCode: '',
      countryCode: '250'
    },
    statutesDocumentId: null,
    joDocumentId: null,
    proofOfExistenceDocument: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    statutesDocument: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    state: 'Incomplete'
  },
  representative: {
    firstName: '',
    lastName: '',
    nationality: '250',
    birthDate: '',
    email: '',
    positionCode: 0,
    identityDocumentId: null,
    identityBackDocumentId: null,
    passportDocumentId: null,
    identityBisDocumentId: null,
    administratorsDocumentId: null,
    proofOfDelegationDocumentId: null,
    identityType: 'idCard',
    identityFront: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    identityBack: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    identityBis: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    passport: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    proofOfAddress: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    proofOfDelegation: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    administrators: {
      id: null,
      fileName: '',
      publicUrl: ''
    },
    state: 'Incomplete'
  },
  references: {
    iso3166Country: [{ value: '', text: '' }],
    iso3166CountryNationality: [{ value: '', text: '' }],
    europeanUnionCountry: [{ value: '', text: '' }],
    representativeFunction: [{ value: '', text: '' }],
    memberFunction: [{ value: '', text: '' }]
  },
  imgErrors: {
    representative: {
      identityFront: null,
      identityBack: null,
      identityBis: null,
      passport: null,
      proofOfAddress: null,
      proofOfDelegation: null,
      administrators: null
    },
    legalInformation: {
      proofOfExistenceDocument: null,
      statutesDocument: null
    },
    bankInformation: {
      ibanDocument: null
    }
  },
  isCurrentlyModify: false,
  features: [],
  provider: '',
  haApiError: false,
  hasRaisedFunds: false,
  hasRestrictedData: false
})

export const getters = {
  iso3166Country: (state) => state.references.iso3166Country,
  iso3166CountryNationality: (state) => state.references.iso3166CountryNationality,
  europeanUnionCountry: (state) => state.references.europeanUnionCountry,
  representativeFunction: (state) => state.references.representativeFunction,
  memberFunction: (state) => state.references.memberFunction,
  isNotAnEuropeanUnionCountry: (state) => {
    const euCountriesValueList = state.references.europeanUnionCountry.map((country) =>
      country.value.toString()
    )
    return !euCountriesValueList.some(
      (value) => value === state.representative.nationality.toString()
    )
  },
  imageError: (state) => (nominativePath) => properties(state.imgErrors, nominativePath),
  isStepImagesValid: (state) => (step) =>
    Object.values(state.imgErrors[step]).every((value) => value === null),
  isCompleteAndNotInModification: (state) =>
    state.organizationState === orgState.VALID && !state.isCurrentlyModify,
  isHaPayCandidate: (state) => {
    return (
      !!state.features.length &&
      !!state.features.find(
        (feature) => feature.name === 'HAPAY_CANDIDATE' && feature.enabled === true
      )
    )
  },
  isKYCNewPageCandidate: (state) => {
    return (
      !!state.features.length &&
      !!state.features.find(
        (feature) => feature.name === 'STRIPE_NEW_PAGE' && feature.enabled === true
      )
    )
  },
  isBoardMemberListEnabled: (state) => {
    const memberListFeature = state.features?.find((feature) => feature.name === 'MEMBER_LIST')
    if (!memberListFeature) return false
    return memberListFeature.enabled
  },
  areAllStepsComplete: (state) => {
    return (
      state.bankInformation.state === stepState.COMPLETE &&
      state.legalInformation.state === stepState.COMPLETE &&
      state.representative.state === stepState.COMPLETE
    )
  },
  isReadyToSend: (state) => {
    return (
      (state.organizationState === orgState.INITIAL && getters.areAllStepsComplete(state)) ||
      (state.organizationState === orgState.INCOMPLETE && getters.areAllStepsComplete(state)) ||
      (state.organizationState === orgState.VALID &&
        state.isCurrentlyModify &&
        getters.areAllStepsComplete(state))
    )
  }
}

export const mutations = <MutationTree<State>>{
  SET_ORGANIZATION_STATE(state, organizationState: string) {
    state.organizationState = organizationState
  },
  SET_VERIFICATION_DATE(state, verificationDate: string) {
    state.verificationDate = verificationDate
  },
  SET_VERIFICATION_MESSAGE(state, verificationMessage: string) {
    state.verificationMessage = verificationMessage
  },
  SET_RESTRICTED_DATA(state, hasRestrictedData: boolean) {
    state.hasRestrictedData = hasRestrictedData
  },
  SET_BANK_INFOS(state, bankInfos: BankInformation) {
    /* We don't want to override ribDocumentId so we have to merge state and commit payload */
    state.bankInformation = {
      ...state.bankInformation,
      ...bankInfos,
      ribDocumentId: bankInfos?.ibanDocument?.id || state.bankInformation.ribDocumentId
    }
  },
  SET_IBAN(state, iban: string) {
    state.bankInformation.iban = iban
  },
  SET_BIC(state, bic: string) {
    state.bankInformation.bic = bic
  },
  SET_PROOFOFIBAN_ID(state, ribId: number) {
    state.bankInformation.ribDocumentId = ribId
  },
  SET_PROOFOFIBAN_DOCUMENT(state, ibanDocument) {
    state.bankInformation.ibanDocument = ibanDocument
  },
  SET_REPRESENTATIVE(state, representative: Representative) {
    state.representative = {
      ...state.representative,
      ...representative,
      identityDocumentId:
        representative?.identityFront?.id || state.representative.identityDocumentId,
      identityBackDocumentId:
        representative?.identityBack?.id || state.representative.identityBackDocumentId,
      passportDocumentId: representative?.passport?.id || state.representative.passportDocumentId,
      identityBisDocumentId:
        representative?.identityBis?.id || state.representative.identityBisDocumentId,
      administratorsDocumentId:
        representative?.administrators?.id || state.representative.administratorsDocumentId
    }
    // make sure the representative is always synced with the first board member
    const updated_representative = {
      ...state.boardMembers[0],
      firstName: representative.firstName,
      lastName: representative.lastName,
      positionCode: representative.positionCode
    }
    state.boardMembers = [updated_representative, ...state.boardMembers.slice(1)]
  },
  SET_IDENTITY_TYPE(state, identityType: string) {
    state.representative.identityType = identityType
  },
  SET_FIRST_NAME(state, firstName: string) {
    state.representative.firstName = firstName
    const updated_representative = {
      ...state.boardMembers[0],
      firstName
    }
    state.boardMembers = [updated_representative, ...state.boardMembers.slice(1)]
  },
  SET_LAST_NAME(state, lastName: string) {
    state.representative.lastName = lastName
    const updated_representative = {
      ...state.boardMembers[0],
      lastName
    }
    state.boardMembers = [updated_representative, ...state.boardMembers.slice(1)]
  },
  SET_EMAIL(state, email: string) {
    state.representative.email = email
  },
  SET_BIRTH_DATE(state, birthDate: string) {
    state.representative.birthDate = birthDate
  },
  SET_NATIONALITY(state, nationality: string) {
    state.representative.nationality = nationality
  },
  SET_POSITION(state, representativePosition: number) {
    state.representative.positionCode = representativePosition
    state.boardMembers[0].positionCode = representativePosition
  },
  SET_IDENTITY_ID(state, identityCardRectoId: number) {
    state.representative.identityDocumentId = identityCardRectoId
    state.representative.passportDocumentId = null
    state.representative.identityType = 'idCard'
    state.representative.passport = {
      id: null,
      fileName: '',
      publicUrl: ''
    }
  },
  SET_IDENTITYBACK_ID(state, identityCardVersoId: number) {
    state.representative.identityBackDocumentId = identityCardVersoId
    state.representative.passportDocumentId = null
    state.representative.identityType = 'idCard'
    state.representative.passport = {
      id: null,
      fileName: '',
      publicUrl: ''
    }
  },
  SET_PASSPORT_ID(state, passportId: number) {
    state.representative.passportDocumentId = passportId
    state.representative.identityDocumentId = null
    state.representative.identityType = identityDocuments.PASSPORT
    state.representative.identityFront = {
      id: null,
      fileName: '',
      publicUrl: ''
    }
    state.representative.identityBackDocumentId = null
    state.representative.identityBack = {
      id: null,
      fileName: '',
      publicUrl: ''
    }
  },
  SET_IDENTITYBIS_ID(state, identityDocumentBisId: number) {
    state.representative.identityBisDocumentId = identityDocumentBisId
  },
  SET_ADMINISTRATORS_ID(state, membersListId: number) {
    state.representative.administratorsDocumentId = membersListId
  },
  SET_PROOFOFDELEGATION_ID(state, proofOfDelegationId: number) {
    state.representative.proofOfDelegationDocumentId = proofOfDelegationId
  },
  SET_IDENTITY_DOCUMENT(state, idCardRectoDocument) {
    state.representative.identityFront = idCardRectoDocument
  },
  SET_IDENTITYBACK_DOCUMENT(state, idCardVersoDocument) {
    state.representative.identityBack = idCardVersoDocument
  },
  SET_IDENTITYBIS_DOCUMENT(state, identityBisDocument) {
    state.representative.identityBis = identityBisDocument
  },
  SET_PASSPORT_DOCUMENT(state, passportDocument) {
    state.representative.passport = passportDocument
  },
  SET_ADMINISTRATORS_DOCUMENT(state, administratorsDocument) {
    state.representative.administrators = administratorsDocument
  },
  SET_PROOFOFDELEGATION_DOCUMENT(state, proofOfDelegationDocument) {
    state.representative.proofOfDelegation = proofOfDelegationDocument
  },

  SET_ORGANISM(state, legalInformation: LegalInformation) {
    state.legalInformation = {
      ...state.legalInformation,
      ...legalInformation,
      address: {
        ...state.legalInformation.address,
        ...legalInformation.address
      },
      statutesDocumentId:
        legalInformation?.statutesDocument?.id || state.legalInformation.statutesDocumentId,
      joDocumentId:
        legalInformation?.proofOfExistenceDocument?.id || state.legalInformation.joDocumentId
    }
  },
  SET_ORG_NAME(state, legalEntity: string) {
    state.legalInformation.name = legalEntity
  },
  SET_RNA(state, rna: string) {
    state.legalInformation.rnaNumber = rna
  },
  SET_ORG_STREET(state, street: string) {
    state.legalInformation.address.street = street
  },
  SET_ORG_CITY(state, city: string) {
    state.legalInformation.address.city = city
  },
  SET_ORG_ZIP_CODE(state, zipCode: string) {
    state.legalInformation.address.zipCode = zipCode
  },
  SET_ORG_COUNTRY(state, countryCode: string) {
    state.legalInformation.address.countryCode = countryCode
  },
  SET_PURPOSE(state, purpose: string) {
    state.legalInformation.purpose = purpose
  },
  SET_STATUTES_ID(state, statutesId: number) {
    state.legalInformation.statutesDocumentId = statutesId
  },
  SET_STATUTES_DOCUMENT(state, statutesDocument) {
    state.legalInformation.statutesDocument = statutesDocument
  },
  SET_PROOFOFEXISTENCE_DOCUMENT(state, joDocument) {
    state.legalInformation.proofOfExistenceDocument = joDocument
  },
  SET_PROOFOFEXISTENCE_ID(state, joId: number) {
    state.legalInformation.joDocumentId = joId
  },
  SET_REFERENCES(state, references: References) {
    state.references = references
  },
  SET_HA_API_ERROR(state, haApiError: boolean) {
    state.haApiError = haApiError
  },
  SET_IMG_ERRORS(state, { step, fileTypeKey, value }) {
    state.imgErrors = {
      ...state.imgErrors,
      [step]: { ...state.imgErrors[step], [fileTypeKey]: value }
    }
  },
  SET_MODIFICATION(state, isModifiyng: boolean) {
    state.isCurrentlyModify = isModifiyng
  },
  SET_FEATURES(state, features: Feature[]) {
    state.features = features
  },
  SET_PROVIDER(state, provider: string) {
    state.provider = provider
  },
  SET_HAS_RAISED_FUNDS(state, hasRaisedFunds: boolean) {
    state.hasRaisedFunds = hasRaisedFunds
  },
  SET_IS_BOARD_MEMBERS_NEW(state, isBoardMembersNew: boolean) {
    state.isBoardMembersNew = isBoardMembersNew
  },
  SET_BOARD_MEMBERS(state, boardMembers: BoardMember[]) {
    state.boardMembers = boardMembers
  },
  ADD_BOARD_MEMBER(state) {
    state.boardMembers.push({
      id: uuidv4(),
      firstName: '',
      lastName: '',
      positionCode: 0,
      isRepresentative: false
    })
  },
  REMOVE_BOARD_MEMBER(state, id: string) {
    state.boardMembers = state.boardMembers.filter((member) => member.id !== id)
  },
  UPDATE_BOARD_MEMBER(state, newMember: BoardMember) {
    const newBoardMembers = state.boardMembers.map((member) => {
      if (member.id === newMember.id) {
        return newMember
      }
      return member
    })
    state.boardMembers = newBoardMembers
  }
}

export const actions = <ActionTree<State, any>>{
  fetchKycData({ commit, state }, { orgSlug }: { orgSlug: string }) {
    if (state.organizationState === orgState.VALID && state.isCurrentlyModify) return state
    return this.$api
      .get(`/organizations/${orgSlug}/compliance/agg`)
      .then((response: AxiosResponse) => {
        const { data, status } = response
        commit('SET_ORGANIZATION_STATE', data.organizationState)
        commit('SET_VERIFICATION_DATE', data.verificationDate)
        commit('SET_VERIFICATION_MESSAGE', data.verificationMessage)
        commit('SET_BANK_INFOS', data.bankInformation)
        commit('SET_ORGANISM', data.legalInformation)
        commit('SET_HAS_RAISED_FUNDS', data.hasRaisedFunds)

        commit('SET_REPRESENTATIVE', {
          ...data.representative,
          identityType: data.representative?.passport?.id
            ? identityDocuments.PASSPORT
            : identityDocuments.ID_CARD,
          birthDate: data.representative?.birthDate
            ? formatUtcDateStringWithoutTimezone(data.representative.birthDate, 'DD/MM/YYYY')
            : ''
        })

        if (getters.isBoardMemberListEnabled(state) && data.memberList) {
          commit('SET_IS_BOARD_MEMBERS_NEW', data.memberList.length === 0)

          const boardMembersData = boardMembersListApiToStore(data.memberList, state.representative)

          commit('SET_BOARD_MEMBERS', boardMembersData)
        }
        if (status === 206) {
          commit('SET_RESTRICTED_DATA', true)
        }
        if (status === 200) {
          commit('SET_RESTRICTED_DATA', false)
        }

        return data
      })
  },
  /**
   * Upload a document
   * @param {string} orgSlug
   * @param {string} docType could be one of
   * "Identity", "IdentityBack", "Passport", "ProofOfAddress", "ProofOfIban", "ProofOfExistence", "ProofOfDelegation", "Statutes", "Administrators", "IdentityBis"
   * @param {File} file
   */
  postDocument(
    { commit },
    { orgSlug, docType, file }: { orgSlug: string; docType: string; file: File | any }
  ) {
    const url = `/organizations/${orgSlug}/compliance/folder/document?type=${docType}`

    /* By uploading a file, we can't use Content-Type: application/json.
    We must use Content-Type: multipart/form-data instead.
    To do so, we need to add an header and create a FormData*/
    const formData = new FormData()
    formData.append('file', file)

    return this.$api
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        const DOCTYPE = docType.toUpperCase()
        commit(`SET_${DOCTYPE}_ID`, response.data.id)
        commit(`SET_${DOCTYPE}_DOCUMENT`, { ...response.data })
        return response.data
      })
  },
  /**
   * Save organization's bank information
   * @param {string} orgSlug
   */
  postBankInfos({ state }, { orgSlug }: { orgSlug: string }) {
    const formattedIban = { iban: state.bankInformation.iban.replace(/\s/g, '') }
    const url = `/organizations/${orgSlug}/compliance/folder/bank-information`
    /* remove white space for iban before send it to the back */
    const bankInformationToSend = {
      ...state.bankInformation,
      ...formattedIban
    }
    delete bankInformationToSend.ibanDocument
    delete bankInformationToSend.state
    return this.$api.post(url, bankInformationToSend)
  },
  /**
   * Update representative
   * @param {string} orgSlug
   * @param {object} representative
   */
  putRepresentative({ state }, { orgSlug }: { orgSlug: string }) {
    const url = `/organizations/${orgSlug}/compliance/folder/representative`
    const OTHER_POSITION_CODE = 6
    const positionCode = state.representative.positionCode
    const representativeToSend = {
      firstName: state.representative.firstName,
      lastName: state.representative.lastName,
      birthDate: new DateOperator(state.representative.birthDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      nationality: state.representative.nationality,
      email: state.representative.email,
      positionCode,
      identityDocumentId: state.representative.identityDocumentId,
      identityBackDocumentId: state.representative.identityBackDocumentId,
      passportDocumentId: state.representative.passportDocumentId,
      identityBisDocumentId: state.representative.identityBisDocumentId,
      administratorsDocumentId: state.representative.administratorsDocumentId,
      proofOfDelegationDocumentId:
        positionCode === OTHER_POSITION_CODE
          ? state.representative.proofOfDelegationDocumentId
          : null
    }
    return this.$api.put(url, representativeToSend)
  },
  /**
   * Update legal information
   * @param {string} orgSlug
   * @param {object} legalInformation
   */
  putLegalInformation({ state }, { orgSlug }: { orgSlug: string }) {
    const url = `/organizations/${orgSlug}/compliance/folder/legal-information`
    const legalInformationToSend = { ...state.legalInformation }
    delete legalInformationToSend.proofOfExistenceDocument
    delete legalInformationToSend.statutesDocument
    delete legalInformationToSend.state
    return this.$api.put(url, legalInformationToSend)
  },
  /**
   * get countries/nationalities/positions/european union countries list
   */
  fetchReferences({ commit }) {
    const url = `/compliance/references`
    return this.$api
      .get(url)
      .then((response) => {
        commit('SET_REFERENCES', response.data)
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Send organization's kyc information to provider
   * Body is not required as the server already has informations about bank/organims/representative from previous calls`
   * @param {string} orgSlug
   */
  postKycToProvider({}, { orgSlug }: { orgSlug: string }) {
    const url = `/organizations/${orgSlug}/compliance/folder`
    return this.$api.post(url)
  },
  /**
   * get feature list
   */
  fetchFeatures({ commit }, { orgSlug }: { orgSlug: string }) {
    return this.$api
      .get(`/organizations/${orgSlug}/compliance/features`)
      .then((response) => {
        commit('SET_HA_API_ERROR', false)
        commit('SET_FEATURES', response.data)
        return response.data
      })
      .catch((error) => {
        commit('SET_HA_API_ERROR', true)
        throw error
      })
  },
  /**
   * get provider name
   */
  fetchProvider({ commit }, { orgSlug }: { orgSlug: string }) {
    return this.$api
      .get(`/organizations/${orgSlug}/provider`)
      .then((response) => {
        commit('SET_HA_API_ERROR', false)
        commit('SET_PROVIDER', response.data)
        return response.data
      })
      .catch((error) => {
        commit('SET_HA_API_ERROR', true)
        throw error
      })
  },
  sendBoardMembersList({ state }, { orgSlug }: { boardMembers: BoardMember[]; orgSlug: string }) {
    const data = state.boardMembers.map((member: BoardMember) => {
      return {
        firstName: member.firstName,
        lastName: member.lastName,
        positionCode: member.positionCode
      }
    })

    const methodCall = state.isBoardMembersNew ? this.$api.post : this.$api.put

    return methodCall(`/organizations/${orgSlug}/compliance/folder/member-list`, data)
  }
}

// Mappers, file could be split into models, mapper, store

function boardMembersListApiToStore(
  boardMembersListApi: BoardMemberAPI[],
  representative: Representative
): BoardMember[] {
  function isRepresentative(member: BoardMemberAPI) {
    return (
      member.firstName === representative.firstName &&
      member.lastName === representative.lastName &&
      member.positionCode === representative.positionCode
    )
  }

  // the list is reorganized in the store so the representative comes first
  const boardMembersData = boardMembersListApi.map((member) => {
    return {
      ...member,
      isRepresentative: isRepresentative(member),
      id: uuidv4()
    }
  })

  // Move the representative to the beginning of the array
  const representativeIndex = boardMembersData.findIndex((member) => member.isRepresentative)
  if (representativeIndex === -1) {
    // If the representative is not in the list, add it
    boardMembersData.unshift({
      id: uuidv4(),
      firstName: representative.firstName,
      lastName: representative.lastName,
      positionCode: representative.positionCode,
      isRepresentative: true
    })
  } else {
    const representative = boardMembersData.splice(representativeIndex, 1)[0]
    boardMembersData.unshift(representative)
  }

  return boardMembersData
}
