import qs from 'qs'
import { getJwtInfo } from '../helpers/cookies'
import { refreshTokenCookieName, accessTokenCookieName } from '../constants'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Organization, Organizations, User } from '@/components/users/user.interface'
import { haMoment } from '@ha/helpers'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}
interface Getters {
  isUserLoaded(state: State): Boolean
  areUserOrganisationsLoaded(state: State): Boolean
  partiallyShownPhoneNumber(state: State): String | undefined
  userPhoneNumber(state: State): String | undefined
  organizations(state: State): Organizations
  user(state: State): User | null
}

class State {
  token: Object = {}
  userIsVerified: Boolean = false
  fetching: Boolean = false
  user: User | null = null
  organizations: Organizations = {}
  isTwoFaVerified: Boolean = false
}

export const state = () => new State()

export const getters: Getters = {
  isUserLoaded: (state) => !!state.user,
  areUserOrganisationsLoaded: (state) => !!Object.keys(state.organizations).length,
  userPhoneNumber: (state) => state?.user?.phone,
  partiallyShownPhoneNumber: (state) =>
    state?.user?.phone && state?.user?.phone.replace(/\d(?=\d{3})/g, '*'),
  organizations: (state) => state.organizations,
  user: (state) => state.user
}

export const mutations = <MutationTree<State>>{
  GET_TOKEN(state, accessToken) {
    state.token = {
      ...accessToken
    }
  },
  FETCHING_TOKEN(state, fetching: Boolean) {
    state.fetching = fetching
  },
  REVOKE_TOKEN(state) {
    state.token = {}
    state.user = null
  },
  SET_USER(state, user: User) {
    state.user = user
  },
  SET_ORGANIZATIONS(state, organizations: Organization[]) {
    state.organizations = organizations.reduce((newOrganizations, organization) => {
      newOrganizations[organization.organizationSlug] = organization
      return newOrganizations
    }, {})
  },
  IS_USER_VERIFIED(state, isVerified: Boolean) {
    state.userIsVerified = isVerified
  },
  SET_IS_TWO_FA_VERIFIED(state, isTwoFaVerified: Boolean) {
    state.isTwoFaVerified = isTwoFaVerified
  }
}

export const actions = <ActionTree<State, any>>{
  refreshToken({ commit }) {
    const grantPayload = {
      grant_type: 'refresh_token',
      scope: '',
      refresh_token: this.$cookies.get(refreshTokenCookieName)
    }

    commit('FETCHING_TOKEN', true)

    return this.$auth
      .post(`/token`, qs.stringify(grantPayload))
      .then((response) => {
        if (response.data.error) {
          throw new Error('[oauth refresh_token] cannot fetch a valid token ')
        }
        commit('GET_TOKEN', response.data)
        commit('FETCHING_TOKEN', false)
        return response.data
      })
      .catch((e) => {
        // could not refresh the token, remove the token from the store so that the interceptors will fetch a fresh one.
        commit('REVOKE_TOKEN')
        commit('FETCHING_TOKEN', false)
        // keep the error flowing for the consumers
        throw e
      })
  },

  checkIfUserIsVerified({ commit }) {
    const cookieToken = this.$cookies.get(accessTokenCookieName)
    const refreshToken = this.$cookies.get(refreshTokenCookieName)
    if (cookieToken) {
      const { isExpired, isAnonymousToken } = getJwtInfo(cookieToken)

      if (isAnonymousToken || (isExpired && !refreshToken)) {
        return false
      } else {
        commit('IS_USER_VERIFIED', true)
        return true
      }
    }
    return false
  },

  fetchUser({ commit, getters }, forceReload = false) {
    if (!forceReload && getters.isUserLoaded && getters.areUserOrganisationsLoaded)
      return Promise.resolve()

    return this.$api
      .get('/agg/user')
      .then((response) => {
        commit('SET_USER', response.data.user)
        commit('SET_ORGANIZATIONS', response.data.organizations)
      })
      .catch((error) => {
        throw error
      })
  },

  disconnect() {
    const connectedToken = this.$cookies.get(accessTokenCookieName)
    return this.$auth.get(`/disconnect`, {
      withCredentials: true, // the response will invalidate every auth cookies (old and new)
      headers: { Authorization: `Bearer ${connectedToken}` }
    })
  },

  /**
   * Verify connected user's password
   * @param {string} password
   */
  verifyPassword(_, { password }: { password: string }) {
    return this.$api.post('/users/me/password/verify', {
      password
    })
  },

  verifyTwoFa({ commit }) {
    return this.$api
      .get('/users/me/2fa')
      .then((response) => {
        const today = haMoment()
        const expirationDate = response.data?.expirationDate
          ? haMoment(response.data?.expirationDate).subtract(10, 'second')
          : null
        const isUserVerified = expirationDate ? !today.isAfter(expirationDate) : false
        commit('SET_IS_TWO_FA_VERIFIED', isUserVerified)
      })
      .catch((error) => {
        commit('SET_IS_TWO_FA_VERIFIED', false)
        throw error
      })
  },

  sendCodeTwoFa({ commit }) {
    commit('SET_IS_TWO_FA_VERIFIED', false)
    return this.$api.post('/users/me/sms-otp/init')
  },

  validateCodeTwoFa({ commit }, oneTimeToken: string) {
    return this.$api
      .post('/users/me/sms-otp/validate', { oneTimeToken })
      .then(() => {
        commit('SET_IS_TWO_FA_VERIFIED', true)
      })
      .catch((error) => {
        commit('SET_IS_TWO_FA_VERIFIED', false)
        throw error
      })
  },

  // Check if phone number is conformed and send email with OTP to user
  updateUserPhone({ state }, phone: string) {
    return this.$api.post('/users/me/phone/change', {
      phone
    })
  },

  // Check if OTP is conformed and save new phone number
  validateCodeUpdatePhone({ dispatch }, oneTimeToken: string) {
    return new Promise((resolve, reject) => {
      this.$api
        .post('/users/me/phone/verify', { oneTimeToken })
        .then(() => {
          dispatch('fetchUser', true)
            .then(() => {
              resolve(true)
            })
            .catch((errorFetchUser) => reject(errorFetchUser))
        })
        .catch((errorValidate) => reject(errorValidate))
    })
  }
}
