
import { OrganizationStatus } from '@/helpers/enums'
import withImageProxy from '@/mixins/withImageProxy'
import { HaButton } from '@ha/components'

export default {
  name: 'OrganizationLogo',
  components: { HaButton },
  mixins: [withImageProxy],
  computed: {
    isFormAdmin() {
      return ['FormAdmin'].includes(
        this.$store.state.user.organizations[this.$route.params.organization]?.role
      )
    },
    organization() {
      return this.$store.state.organizations.enriched[this.organizationSlug] || {}
    },
    organizationSlug() {
      return this.$route.params.organization
    },
    organizationType() {
      const type = this.organization.type

      if (Object.keys(OrganizationStatus).includes(type)) {
        return this.$t(`organizationsTypes.${type}`)
      }
      return null
    }
  },
  methods: {
    tr(name) {
      return this.$t(`layouts.${name}`)
    },
    boLink() {
      this.$router.push(`/${this.organizationSlug}/accueil`)
    }
  }
}
