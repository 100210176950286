import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status } from '../helpers/tsenums'
import { ConfigEnv } from '@/composables/useConfigEnv'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

export interface Order {
  payer: Payer
  items: OrderItem[]
  payments: OrderPayment[]
  amount: Amount
  id: number
  date: Date
  formSlug: string
  formType: string
  organizationName: string
  organizationSlug: string
  meta: Meta
  isAmountHidden: boolean
}

export interface Amount {
  total: number
  vat: number
  discount: number
}

export interface OrderItem {
  payments: ItemPayment[]
  name: string
  user: User
  priceCategory: string
  dayOfLevy: number
  tierDescription: string
  id: number
  amount: number
  type: string
  initialAmount: number
  state: string
}

export interface ItemPayment {
  id: number
  shareAmount: number
}

export interface User {
  firstName: string
  lastName: string
}

export interface Meta {
  createdAt: Date
  updatedAt: Date
}

export interface Payer {
  dateOfBirth: Date
  email: string
  address: string
  city: string
  zipCode: string
  country: string
  company: string
  firstName: string
  lastName: string
}

export interface OrderPayment {
  items: PaymentItem[]
  id: number
  amount: number
  date: Date
  paymentMeans: string
  state: string
  meta: Meta
}

export interface PaymentItem {
  id: number
  shareAmount: number
  shareItemAmount: number
}

class State {
  order: Order | Object = {}
  status: Status = Status.SUCCESS
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  SET_CURRENT_ORDER(state, order: Order) {
    state.order = order
  },
  SET_ORDERS_STATUS(state, status: Status) {
    state.status = status
  }
}

export const actions = <ActionTree<State, any>>{
  /**
   * Fetch current order with details
   */
  fetchCurrentOrder({ commit }, { orderId }: { orderId: string }) {
    commit('SET_ORDERS_STATUS', Status.LOADING)

    return this.$api
      .get(`/orders/${orderId}`)
      .then((response) => {
        commit('SET_CURRENT_ORDER', response.data)
        commit('SET_ORDERS_STATUS', Status.SUCCESS)
        return response.data
      })
      .catch((error) => {
        commit('SET_ORDERS_STATUS', Status.ERROR)
        throw error
      })
  }
}
