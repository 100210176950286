
import { functions } from '@ha/helpers'
import { HaModal, HaButton } from '@ha/components'

export default {
  name: 'RefundModal',
  components: { HaModal, HaButton },
  props: {
    loading: Boolean,
    currentFormTitle: String,
    amount: Number
  },
  computed: {
    amountIsZero() {
      return this.amount === 0
    },
    formattedAmount() {
      return functions.convertToEuros(this.amount)
    },
    conditionsOfUseUrl() {
      return 'https://centredaide.helloasso.com/s/article/comment-puis-je-effectuer-un-remboursement-sur-helloasso#:~:text=Comment%20suivre%20le%20remboursement%20%3F&text=Au%20moment%20o%C3%B9%20le%20remboursement%20sera%20pass%C3%A9%20sur%20le%20statut,participation%20%C3%A0%20notre%20mod%C3%A8le%20%C3%A9conomique'
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    },
    tr(name, args) {
      return this.$t(`views.sidebar.form.refundModal.${name}`, args)
    }
  }
}
