/**
 * Get form's key used to stock form in the store
 * @param {object} params Object wich contains orgSlug, formSlug and type of current form
 * @returns {string} form's key
 */
export default params => {
  if (!params?.orgSlug || !params?.formSlug || !params?.type) {
    throw new Error('Missing required params')
  }
  return `${params.orgSlug}-${params.type.toUpperCase()}-${params.formSlug}`
}
