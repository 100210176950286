import { ref } from 'vue'
import { useStore, useContext } from '@nuxtjs/composition-api'
import formatError from '@/helpers/format-error'
import useStoreData from '@/composables/useStoreData'
import useNotifications from '@/composables/useNotifications'
import useUserRole from '@/composables/useUserRole'

const isLoading = ref({
  organization: false,
  provider: false,
  formTypes: false,
  paymentsCount: false,
  user: false,
  cashout: false
})

export default () => {
  const { storeRouteParams, organization } = useStoreData()
  const store = useStore()
  const { notifyError } = useNotifications()
  const { i18n } = useContext()
  const { isOrganizationAdmin } = useUserRole()

  const fetchUser = async () => {
    try {
      isLoading.value.user = true
      await store.dispatch('user/fetchUser', storeRouteParams.value)
    } catch (error) {
      notifyError(error, {
        title: i18n.t('layouts.fetch.errorTitleUser'),
        body: formatError(error, i18n)
      })
      throw error
    } finally {
      isLoading.value.user = false
    }
  }
  const fetchOrganization = async () => {
    try {
      isLoading.value.organization = true
      await store.dispatch('organizations/fetchOrganizationEnriched', storeRouteParams.value)
    } catch (error) {
      notifyError(error, {
        title: i18n.t('layouts.fetch.errorTitleOrganization'),
        body: formatError(error, i18n)
      })
      throw error
    } finally {
      isLoading.value.organization = false
    }
  }
  const fetchFormTypes = async () => {
    try {
      isLoading.value.formTypes = true
      await store.dispatch('organizations/fetchFormTypes', storeRouteParams.value)
    } catch (error) {
      notifyError(error, {
        title: i18n.t('layouts.fetch.errorTitleFormTypes'),
        body: formatError(error, i18n)
      })
      throw error
    } finally {
      isLoading.value.formTypes = false
    }
  }
  const fetchPaymentsCount = async () => {
    try {
      isLoading.value.paymentsCount = true
      await store.dispatch('payments/getPaymentsCount', storeRouteParams.value)
    } catch (error) {
      notifyError(error, {
        title: i18n.t('layouts.fetch.errorTitlePaymentsCount'),
        body: formatError(error, i18n)
      })
      throw error
    } finally {
      isLoading.value.paymentsCount = false
    }
  }
  const fetchProvider = async () => {
    try {
      isLoading.value.provider = true
      await store.dispatch('compliance/fetchProvider', storeRouteParams.value)
    } catch (error) {
      notifyError(error, {
        title: i18n.t('layouts.fetch.errorTitleProvider'),
        body: formatError(error, i18n)
      })
      throw error
    } finally {
      isLoading.value.provider = false
    }
  }

  const fetchCashOut = async () => {
    // Child organizations cannot access to amounts
    if (organization.value.parentOrganizationSlug) return

    try {
      isLoading.value.cashout = true
      await store.dispatch('organizations/fetchOrganizationCashOut', storeRouteParams.value)
    } catch (error) {
      notifyError(error, {
        title: i18n.t('layouts.fetch.errorTitleCashOut'),
        body: formatError(error, i18n)
      })
      throw error
    } finally {
      isLoading.value.cashout = false
    }
  }

  const fetchOrganizationInformations = async () => {
    await fetchUser()
    await fetchOrganization()
    await fetchFormTypes()
    if (isOrganizationAdmin.value) {
      await fetchProvider()
      await fetchPaymentsCount()
    }
  }

  return {
    fetchOrganization,
    fetchFormTypes,
    fetchPaymentsCount,
    fetchCashOut,
    fetchProvider,
    fetchOrganizationInformations,
    isLoading
  }
}
