import axios from 'axios'
import https from 'https'
import { getTokens } from '@/cookies'
import getUnauthorizedRedirection from '@/helpers/redirection/getUnauthorizedRedirection'
import { functions } from '@ha/helpers'

const timeout = 50000
const redirectAfterUnauthorized = (store, configEnv) => {
  const { accessToken } = getTokens()
  const redirectUrl = getUnauthorizedRedirection(
    configEnv,
    accessToken,
    store.getters['user/organizations']
  )
  window.location.replace(redirectUrl)
}
const getHttpsAgent = (configEnv) => {
  return new https.Agent({
    rejectUnauthorized: configEnv.NODE_ENV !== 'development'
  })
}
const addBearerToken = (config) => {
  const { accessToken } = getTokens()
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

  return config
}
const interceptorResponse = (response) => response
const interceptorError = async (error, store, configEnv, api) => {
  const config = error.config
  const endpoints = config.url.toString().split('/')
  const lastEndpoint = endpoints[endpoints.length - 1]
  const { refreshToken } = getTokens()

  if (error.response?.status === 401 && !config.retry) {
    console.info('Failed to fetch data: refreshing token...')

    if (refreshToken) {
      config.retry = true

      try {
        await store.dispatch('user/refreshToken')
        const { accessToken } = getTokens()
        config.headers['Authorization'] = `Bearer ${accessToken}`
        console.warn('[dispatch] fetching', lastEndpoint, 'again')
        return api.request(config)
      } catch (errorRefresh) {
        console.error('Error refreshing token:', errorRefresh)
        redirectAfterUnauthorized(store, configEnv)
      }
    } else {
      console.error('No refresh token available:', error)
      redirectAfterUnauthorized(store, configEnv)
    }
  }

  if (!error.config.url.includes('/users/me/password/verify') && error.response?.status === 403) {
    console.error('Forbidden error:', error)

    if (error.config.url === '/agg/user') {
      const redirectUrl = getUnauthorizedRedirection(configEnv)
      window.location.replace(redirectUrl)
      return
    }

    const { accessToken } = getTokens()
    const { userId } = functions.getAccessTokenInformations(accessToken)
    if (userId && userId !== store.getters['user/user']?.userId) {
      await store.dispatch('user/fetchUser', true)
    }

    redirectAfterUnauthorized(store, configEnv)
  }

  return Promise.reject(error)
}

const createAuthApi = (configEnv, store) => {
  const httpAuth = axios.create({
    baseURL: configEnv.NUXT_ENV_AUTH_URL_BO,
    withCredentials: true,
    timeout
  })

  httpAuth.interceptors.response.use(interceptorResponse, (error) =>
    interceptorError(error, store, configEnv, httpAuth)
  )
  return httpAuth
}
const createHttpApi = (configEnv, store) => {
  const httpApi = axios.create({
    withCredentials: true,
    httpsAgent: getHttpsAgent(configEnv),
    baseURL: configEnv.NUXT_ENV_BASE_API_BO,
    timeout
  })

  httpApi.interceptors.request.use(addBearerToken)
  httpApi.interceptors.response.use(interceptorResponse, (error) =>
    interceptorError(error, store, configEnv, httpApi)
  )

  return httpApi
}

const createHttps = (configEnv, store) => {
  const httpApi = createHttpApi(configEnv, store)
  const authApi = createAuthApi(configEnv, store)

  return { httpApi, authApi }
}

export { createHttps }
