import { AxiosRequestConfig } from 'axios'
import { ExportFormat } from '@/components/participants/list/types/participants'

export default (format: ExportFormat): AxiosRequestConfig => ({
  headers: {
    Accept:
      format === 'excel'
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        : 'text/csv'
  },
  responseType: 'blob'
})
