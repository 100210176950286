import { get, size } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { Status, PaymentState, FormType } from '../helpers/tsenums'
import { haMoment } from '@ha/helpers'
import getExportFileName from '@/components/participants/list/helpers/getExportFileName'
import qs from 'qs'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface Payment {
  order: Order
  payer: Payer
  items: Item[]
  paymentProviderType?: PaymentProviderType
  id: number
  amount: number
  date: Date
  paymentMeans: string
  state: PaymentState
  meta: Meta
  type?: string
  paymentOffLineMean?: string
  cashOutState?: string
  paymentReceiptUrl?: string
}

interface Item {
  shareAmount: number
  shareItemAmount: number
  id: number
  amount: number
  type: string
  state: ItemState
}

enum ItemState {
  Processed = 'Processed',
  Registered = 'Registered'
}

interface Meta {
  createdAt: Date
  updatedAt: Date
}

interface Order {
  id: number
  date: Date
  formSlug: string
  formType: string
  organizationName: string
  organizationSlug: string
  formName: string
  meta: Meta
  isAmountHidden: boolean
}

interface Payer {
  email: string
  address?: string
  city?: string
  zipCode?: string
  country?: string
  company?: string
  firstName: string
  lastName: string
}

interface Search {
  query: string
  start: string
  end: string
}

enum PaymentProviderType {
  Lemonway = 'Lemonway',
  Stripe = 'Stripe'
}

class State {
  entities: Payment[] = []
  count: number = 0
  totalPaymentsCount: number = 0
  status: Status = Status.SUCCESS
  remaining: number = 0
  continuationToken: string = ''
  hasNextPayments: boolean = true
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  SET_HAS_NEXT_PAYMENTS(state, hasNextPayments: boolean) {
    state.hasNextPayments = hasNextPayments
  },
  SET_PAYMENTS(state, payments: Payment[]) {
    state.entities = payments
  },
  ADD_PAYMENTS(state, payments: Payment[]) {
    state.entities.push(...payments)
  },
  SET_PAYMENT_REFUNDING(state, paymentId: number) {
    let payment = state.entities.find((payment) => payment.id === paymentId)
    if (payment) payment.state = PaymentState.REFUNDING
  },
  SET_PAYMENTS_COUNT(state, count: number) {
    state.count = count
  },
  SET_TOTAL_PAYMENTS_COUNT(state, totalPaymentsCount: number) {
    state.totalPaymentsCount = totalPaymentsCount
  },
  SET_PAYMENTS_REMAINING(state, remaining: number) {
    state.remaining = remaining
  },
  SET_PAYMENTS_CONTINUATION_TOKEN(state, token: string) {
    state.continuationToken = token
  },
  SET_PAYMENTS_STATUS(state, status: Status) {
    state.status = status
  }
}

export const actions = <ActionTree<State, any>>{
  /**
   * Fetch current organization's payments
   */
  fetchPayments(
    { commit, state },
    {
      getCount,
      orgSlug,
      pageSize = 20,
      reset,
      search
    }: { getCount: boolean; orgSlug: string; pageSize: number; reset: boolean; search: Search }
  ) {
    commit('SET_PAYMENTS_STATUS', Status.LOADING)

    const allowedAPIStates = [
      PaymentState.AUTHORIZED,
      PaymentState.REFUSED,
      PaymentState.REGISTERED,
      PaymentState.ERROR,
      PaymentState.REFUNDED,
      PaymentState.REFUNDING,
      PaymentState.CONTESTED
    ]

    const continuationToken = reset ? '' : state.continuationToken
    if (reset) {
      commit('SET_PAYMENTS_CONTINUATION_TOKEN', '')
    }

    const params = {
      states: allowedAPIStates,
      continuationToken: continuationToken || undefined,
      userSearchKey: search.query || undefined,
      pageSize,
      from: search.start ? haMoment(search.start).startOf('day').format() : undefined,
      end: search.end ? haMoment(search.end).endOf('day').format() : undefined
    }

    return this.$api
      .get(`/organizations/${orgSlug}/payments`, {
        params,
        /* Need to specify paramsSerializer to replace states[]=status1&states[]=status2 by states=status1&states=status2 in url */
        paramsSerializer: (param) => qs.stringify(param, { arrayFormat: 'repeat' })
      })
      .then((response) => {
        const { data } = response
        const payments = data.data
        const token = data.pagination.continuationToken
        const total = data.pagination.totalCount

        if (getCount) {
          commit('SET_PAYMENTS_COUNT', total)
        } else {
          if (reset) {
            commit('SET_PAYMENTS', [])
          }
          commit('SET_HAS_NEXT_PAYMENTS', token !== '' && continuationToken !== token)
          commit('SET_PAYMENTS_CONTINUATION_TOKEN', token)
          commit('SET_PAYMENTS_REMAINING', total - size(payments))

          if (payments) {
            commit('ADD_PAYMENTS', payments)
          }

          commit('SET_PAYMENTS_STATUS', Status.SUCCESS)
        }

        return payments
      })
      .catch((error) => {
        commit('SET_PAYMENTS_STATUS', Status.ERROR)
        throw error
      })
  },

  /**
   * request a refund of a payment
   * A refund is only possible in the following cases
   * payment date is less than 12 months
   * credit card is not expired
   * Payment is not cashed out by organization
   * payment has an Authorized status
   */
  refundPayment({ commit }, { paymentId, formType }: { paymentId: number; formType: FormType }) {
    return this.$api
      .post(`/payments/${paymentId}/refund?cancelOrder=${formType === 'Event'}`)
      .then(() => {
        // Locally amend cashIn status to display that refund is pending
        commit('SET_PAYMENT_REFUNDING', paymentId)
      })
      .catch((error) => {
        throw error
      })
  },

  getPaymentsCount({ commit }, { orgSlug }: { orgSlug: string }) {
    return this.$api
      .get(`/organizations/${orgSlug}/payments`)
      .then((response) => {
        const totalCount = response.data?.pagination?.totalCount || 0
        commit('SET_TOTAL_PAYMENTS_COUNT', totalCount)
        return totalCount
      })
      .catch((error) => {
        throw error
      })
  },

  async downloadPayments(
    _,
    { orgSlug, search, format }: { orgSlug: string; search: Search; format: string }
  ) {
    const params = {
      retrieveAll: true,
      userSearchKey: search.query || undefined,
      from: search.start ? haMoment(search.start).startOf('day').format() : undefined,
      end: search.end ? haMoment(search.end).endOf('day').format() : undefined
    }

    const axiosConfig: any = {
      headers: {
        Accept:
          format === 'excel'
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv'
      },
      responseType: 'blob',
      params
    }

    const { data, headers } = await this.$api.get(`/organizations/${orgSlug}/payments`, axiosConfig)
    const blob = data

    const filename = getExportFileName(headers)

    return { blob, filename }
  }
}
