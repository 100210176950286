import { datadogRum } from '@datadog/browser-rum'

export default ({
  $config: {
    DD_RUM_APPLICATION_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_SITE,
    DD_SERVICE,
    DD_ENV,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE
  },
  env: { DD_NUXT_ENV_TIMESTAMP }
}) => {
  if (!DD_RUM_APPLICATION_ID) {
    console.warn('DD_RUM_APPLICATION_ID is undefined')
    return
  }

  datadogRum.init({
    applicationId: DD_RUM_APPLICATION_ID,
    clientToken: DD_RUM_CLIENT_TOKEN,
    site: DD_SITE,
    service: DD_SERVICE,
    env: DD_ENV,
    sessionSampleRate: DD_RUM_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    version: DD_NUXT_ENV_TIMESTAMP
  })

  datadogRum.startSessionReplayRecording()
}
