import { filter } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface APIKeys {
  clientId: string | null
  clientSecret: string | null
}

interface Partner {
  tokenId: string
  partnerName: string
  userEmail: string
}

class State {
  apiKeys: APIKeys = {
    clientId: null,
    clientSecret: null
  }
  partners: Partner[] = []
  webHookUrl: string | null = null
  webHookUrlIsSet: boolean | null = null
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  SET_API_KEYS(state, { clientId, clientSecret }: { clientId: string; clientSecret: string }) {
    state.apiKeys.clientId = clientId
    state.apiKeys.clientSecret = clientSecret
  },
  SET_CLIENT_ID(state, clientId: string) {
    state.apiKeys.clientId = clientId
  },
  SET_CLIENT_SECRET(state, clientSecret: string) {
    state.apiKeys.clientSecret = clientSecret
  },
  DELETE_API_CLIENT(state) {
    state.apiKeys.clientId = null
    state.apiKeys.clientSecret = null
  },
  SET_PARTNERS(state, partners: Partner[]) {
    state.partners = partners
  },
  DELETE_PARTNER(state, id: string) {
    state.partners = filter(state.partners, (partner) => partner.tokenId !== id)
  },
  DELETE_PARTNERS(state) {
    state.partners = []
  },
  SET_WEB_HOOK_URL(state, url: string) {
    state.webHookUrl = url
  },
  SET_WEB_HOOK_URL_STATUS(state, isSet: boolean) {
    state.webHookUrlIsSet = isSet
  }
}

export const actions = <ActionTree<State, any>>{
  /**
   * Create a clientId + clientSecret pair.
   */
  generateKeys({ commit }, { orgSlug, password }: { orgSlug: string; password: string }) {
    return this.$api
      .post(`/organizations/${orgSlug}/api-clients`, { password })
      .then((response) => {
        commit('SET_API_KEYS', {
          clientId: response.data.id,
          clientSecret: response.data.secret
        })
      })
  },
  fetchClientId({ commit }, { orgSlug }: { orgSlug: string }) {
    return this.$api.get(`/organizations/${orgSlug}/api-clients`).then((response) => {
      commit('SET_CLIENT_ID', response.data[0].id)
    })
  },
  fetchClientSecret({ commit }, { orgSlug, password }: { orgSlug: string; password: string }) {
    return this.$api
      .post(`/organizations/${orgSlug}/api-clients/full`, { password })
      .then((response) => {
        commit('SET_CLIENT_SECRET', response.data[0].secret)
      })
  },
  /**
   * Delete the current clientId + clientSecret pair.
   */
  deleteApiClient(
    { commit },
    { password, orgSlug, clientId }: { orgSlug: string; password: string; clientId: string }
  ) {
    return this.$api
      .delete(`/organizations/${orgSlug}/api-clients/${clientId}`, { data: { password } })
      .then(() => {
        commit('DELETE_API_CLIENT')
      })
  },
  /**
   * Fetch the partners with valid tokens
   */
  fetchPartners({ commit }, { orgSlug }: { orgSlug: string }) {
    return this.$api.get(`/organizations/${orgSlug}/api-jwt`).then((response) => {
      const partners = filter(response.data, (el) => !!el.partnerName)
      commit('SET_PARTNERS', partners)
    })
  },
  /**
   * Revoke a partner
   */
  deletePartner({ commit }, { orgSlug, id }: { orgSlug: string; id: string }) {
    return this.$api
      .post(`/organizations/${orgSlug}/api-jwt/revoke`, { tokenIds: [id] })
      .then(() => {
        commit('DELETE_PARTNER', id)
      })
  },
  /**
   * Revoke all partners
   */
  deletePartners({ commit }, { orgSlug, ids }: { orgSlug: string; ids: string[] }) {
    return this.$api
      .post(`/organizations/${orgSlug}/api-jwt/revoke`, { tokenIds: ids })
      .then(() => {
        commit('DELETE_PARTNERS')
      })
  },
  /**
   * Create the web hook URL
   */
  createWebHookUrl({ commit }, { orgSlug, webHookUrl }: { orgSlug: string; webHookUrl: string }) {
    return this.$api
      .post(`/organizations/${orgSlug}/api-notifications`, { url: webHookUrl })
      .then(() => {
        commit('SET_WEB_HOOK_URL', webHookUrl)
        commit('SET_WEB_HOOK_URL_STATUS', true)
      })
  },
  /**
   * Fetch the web hook URL
   */
  fetchWebHookUrl({ commit }, { orgSlug }: { orgSlug: string }) {
    return this.$api.get(`/organizations/${orgSlug}/api-notifications`).then((response) => {
      if (response?.data) {
        commit('SET_WEB_HOOK_URL', response.data[0].url)
        commit('SET_WEB_HOOK_URL_STATUS', true)
      }
    })
  },
  /**
   * Delete the web hook URL
   */
  deleteWebHookUrl({ commit }, { orgSlug }: { orgSlug: string }) {
    return this.$api.delete(`/organizations/${orgSlug}/api-notifications`).then(() => {
      commit('SET_WEB_HOOK_URL', '')
      commit('SET_WEB_HOOK_URL_STATUS', false)
    })
  }
}
