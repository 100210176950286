import { get, size } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { FormType } from '../helpers/tsenums'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

export type FormActivities = {
  [type in FormType]: FormActivity[]
}

export interface FormActivity {
  id: number
  label: string
  shortLabel: string
}

class State {
  formActivities: FormActivities[] = []
}

export const state = () => new State()

export const getters = <GetterTree<State, any>>{
  getFormActivities: (state) => (type: FormType) => {
    return get(state.formActivities, type)
  }
}

export const mutations = <MutationTree<State>>{
  GET_FORM_ACTIVITIES(state: State, { types, type }: { types: FormActivities; type: FormType }) {
    state.formActivities = {
      ...state.formActivities,
      [type]: types
    }
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchFormActivities(
    { state, commit, getters },
    { type, forceReload = false }: { type: FormType; forceReload: boolean }
  ): Promise<void> {
    if (!forceReload && getters.hasLoadedFormActivities) return Promise.resolve()

    const response = await this.$api.get(`/values/form/${type}/types`)
    commit('GET_FORM_ACTIVITIES', { types: response.data, type })
  }
}
