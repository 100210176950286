import { Tracking } from '@ha/helpers'
import getTrackingRouteData from '@/helpers/getTrackingRouteData.ts'

export default ({ app }) => {
  const tracking = new Tracking({
    segmentKey: app.$config.SEGMENT_KEY,
    enabled: app.$config.SEGMENT_ENABLED
  })
  app.router.afterEach(route => {
    tracking.page(getTrackingRouteData(route))
  })
}
