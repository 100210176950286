import { CustomField, ExtraOption, Picture } from '@/helpers/tsenums'

export enum TierState {
  DISABLED = 'Disabled',
  ENABLED = 'Enabled',
  DELETED = 'Deleted'
}

export interface Term {
  date: Date
  amount: number | null
}

export interface TierTypeOption {
  icon: string
  id: string
  value: string
  label: string
  text: string
  frequency: string
}

export interface Tier {
  id?: number
  customFields?: CustomField[]
  extraOptions?: ExtraOption[]
  state?: TierState
  maxEntries?: number | null
  nbUses?: number
  label: string | null
  description?: string | null
  tierType: string
  price?: number | null
  minAmount?: number | null
  vatRate?: number
  paymentFrequency?: string
  maxPerUser?: number | null
  isEligibleTaxReceipt: boolean
  isExcludedFromFormPaymentTerms?: boolean
  picture?: Picture | string | null
  terms?: Term[] | null
  showMaxEntries: boolean
  paymentFrequencyType: string
  type?: string // use in side panel interface to determine easily the Categorie (FIXED, FREE, ...)
  hasTaxReceipt?: boolean // use in side panel interface
  termsDay?: number // use in side panel interface
  hasMaxEntries?: boolean // use in side panel interface
  hasMaxPerCommand?: boolean // use in side panel interface
}

export type TierApiBody = Omit<Tier, 'id' | 'isIncludedFromFormPaymentTerms'>

export interface TierFront extends Omit<Tier, 'isExcludedFromFormPaymentTerms'> {
  isIncludedFromFormPaymentTerms?: boolean
}
