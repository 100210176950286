export default {
  header: {
    accordianButton: 'BoAsso_MainLayout_Header_AccordianButton',
    organizationDropdown: 'BoAsso_MainLayout_Header_OrganizationDropdown',
    organizationDropdownIcon: 'BoAsso_MainLayout_Header_OrganizationDropdownIcon',
    helpLink: 'BoAsso_MainLayout_Header_HelpLink',
    notificationButton: 'BoAsso_MainLayout_Header_NotificationButton',
    accountDropdown: 'BoAsso_MainLayout_Header_AccountDropdown',
    accountDropdownIcon: 'BoAsso_MainLayout_Header_AccountDropdownIcon'
  },
  sidebar: {
    homeLink: 'BoAsso_MainLayout_HomeLink',
    menuLink: 'BoAsso_MainLayout_MenuLink'
  }
}
