import { computed } from 'vue'
import { useRoute } from '@nuxtjs/composition-api'
import { enum as enums } from '@ha/helpers'

export default () => {
  const route = useRoute()
  const organizationSlug = computed(() => route.value?.params?.organization)
  const formSlug = computed(() => route.value?.params?.form)
  const formType = computed(() => enums.UrlToFormType[route.value?.params?.type])
  const formTypeUrl = computed(() => route.value?.params?.type)
  const formStep = computed(() =>
    route.value.params?.step ? parseInt(route.value.params.step, 10) : null
  )
  const formTab = computed(() => route.value.params?.tab)
  const formActionType = computed(() => route.value.params?.actionType)

  return {
    organizationSlug,
    formSlug,
    formType,
    formTypeUrl,
    formStep,
    formTab,
    formActionType
  }
}
