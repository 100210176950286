export enum DiscountCodeState {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  DELETED = 'Deleted'
}

export enum DiscoutCodeType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage'
}

export interface DiscountCode {
  id: number
  code: string
  state: DiscountCodeState
  nbUses: number
  discountAmount?: number
  discountRate?: number
  maxUses?: number
  validityStartDate?: string
  validityEndDate?: string
  isLinkedToAllTiers: boolean
  linkedTiers?: number[]
}

export type DiscountCodeApiBody = Omit<
  DiscountCode,
  'id' | 'state' | 'nbUses' | 'isLinkedToAllTiers'
> & {
  isGlobal: boolean
}

export interface DiscoutCodeFront {
  id?: number
  type: DiscoutCodeType
  code: string
  discountAmount: number | null
  discountRate: number | null
  maxUses: number | null
  startDate: Date | null
  endDate: Date | null
  isLinkedToAllTiers: boolean
  linkedTiers: number[]
}
