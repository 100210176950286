export default {
  cashout: 'BoAsso_PaymentTracking_Account_Cashout',
  paymentTrackingList: {
    fromDate: 'BoAsso_PaymentTrackingList_FromDate',
    toDate: 'BoAsso_PaymentTrackingList_ToDate',
    searchInput: 'BoAsso_PaymentTrackingList_SearchInput',
    downloadButton: 'BoAsso_PaymentTrackingList_DownloadButton',
    excelFormat: 'BoAsso_PaymentTrackingList_ExcelFormat',
    oldExcelFormat: 'BoAsso_PaymentTrackingList_OldExcelFormat',
    csvFormat: 'BoAsso_PaymentTrackingList_CsvFormat',
    oldCsvFormat: 'BoAsso_PaymentTrackingList_OldCsvFormat',
    dynamicScrollToTop: 'BoAsso_PaymentTrackingList_DynamicScrollToTop',
    settings: 'BoAsso_PaymentTrackingList_Settings',
    paymentCertificate: 'BoAsso_PaymentTrackingList_PaymentCertificate',
    fiscalReceipt: 'BoAsso_PaymentTrackingList_FiscalReceipt',
    refundRequested: 'BoAsso_PaymentTrackingList_RefundRequested',
    details: 'BoAsso_PaymentTrackingList_Details',
    bottomScrollToTop: 'BoAsso_PaymentTrackingList_BottomScrollToTop'
  },
  refundModal: {
    refundButton: 'BoAsso_RefundModal_RefundButton'
  }
}
