
import useConfigEnv from '@/composables/useConfigEnv'

/**
 * Application's sidebar, use the default and footer slot for dynamic content (put a router-view there !)
 *
 * The sidebar is always visible on desktop and have to be toggled on on mobile.
 * The sidebar is automatically toggled on when focusing an element inside of it.
 */
export default {
  name: 'AppSidebar',
  model: {
    prop: 'value',
    form: 'change'
  },
  props: {
    // Show sidebar on mobile
    value: {
      type: Boolean
    }
  },
  setup() {
    const { NUXT_ENV_BASE_URL } = useConfigEnv()

    return { NUXT_ENV_BASE_URL }
  },
  computed: {
    homepageLink() {
      return this.NUXT_ENV_BASE_URL
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        document.querySelector('body').classList.add('sidebar-open')
        window.addEventListener('keydown', this.handleKeyboard)
      } else {
        document.querySelector('body').classList.remove('sidebar-open')
        window.removeEventListener('keydown', this.handleKeyboard)
      }
    }
  },
  mounted() {
    // allow to automatically open sidebar when focusing an element inside of it
    window.addEventListener('focusin', this.handleFocusIn)
  },
  beforeDestroy() {
    window.removeEventListener('focusin', this.handleFocusIn)
  },
  methods: {
    handleKeyboard({ key }) {
      if (key === 'Escape') {
        this.close()
      }
    },
    handleFocusIn({ target }) {
      if (this.$refs.sidebarRef.contains(target)) {
        this.open()
      } else {
        this.close()
      }
    },
    open() {
      this.$emit('change', true)
    },
    close() {
      this.$emit('change', false)
    },
    tr(name, ...other) {
      return this.$t(`layouts.sidebar.${name}`, ...other)
    }
  }
}
