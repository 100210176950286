import Vue from 'vue'
import { UrlToApiTypes, CampaignType } from '@/helpers/enums'

const commonMixin = {
  computed: {
    formKey() {
      return `${this.$route.params.organization}-${UrlToApiTypes[this.$route.params.type]}-${
        this.$route.params.form
      }`
    },
    isMembership() {
      return UrlToApiTypes[this.$route.params.type] === CampaignType.MEMBERSHIP
    },
    isShop() {
      return UrlToApiTypes[this.$route.params.type] === CampaignType.SHOP
    },
    isEvent() {
      return UrlToApiTypes[this.$route.params.type] === CampaignType.EVENT
    },
    isCrowdfunding() {
      return UrlToApiTypes[this.$route.params.type] === CampaignType.CROWDFUNDING
    },
    isDonation() {
      return UrlToApiTypes[this.$route.params.type] === CampaignType.DONATION
    },
    isSale() {
      return UrlToApiTypes[this.$route.params.type] === CampaignType.PAYMENTFORM
    }
  }
}

if (!Vue.commonMixin) {
  Vue.commonMixin = true
  Vue.mixin(commonMixin)
}
