import { onMounted, ref } from 'vue'
import { useContext, useRoute, useRouter } from '@nuxtjs/composition-api'
import salesforce from '@/helpers/salesforce'
import useTracking from '@/composables/useTracking'
import useNotifications from '@/composables/useNotifications'
import useDataLayer from '@/composables/useDataLayer'
import useFetchOrganization from '@/composables/useFetchOrganization'
import useStoreData from '@/composables/useStoreData'
import useUserRole from '@/composables/useUserRole'
import useFetchKyc from '@/composables/useFetchKyc'
import useConfigEnv from '@/composables/useConfigEnv'

export default () => {
  const { user, organization } = useStoreData()
  const { isOrganizationAdmin } = useUserRole()
  const { identifyUser } = useTracking()
  const { sendDataLayer } = useDataLayer()
  const { notifyError } = useNotifications()
  const { fetchOrganizationInformations } = useFetchOrganization()
  const { i18n } = useContext()
  const router = useRouter()
  const route = useRoute()
  const { fetchKycFeatures, fetchKycProvider } = useFetchKyc()
  const configEnv = useConfigEnv()

  const showAccessibilitySettings = ref(false)
  const showSidebar = ref(false)
  const isLoading = ref(false)

  const openAccessibilitySettings = () => {
    showAccessibilitySettings.value = true
  }
  const closeAccessibilitySettings = () => {
    showAccessibilitySettings.value = false
  }
  const closeSidebar = () => {
    showSidebar.value = false
  }
  const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value
  }
  const loadSalesForceScript = async () => {
    const { lastPaymentDate } = organization.value
    salesforce(configEnv, user.value, '', !!lastPaymentDate)
  }
  const setLayout = async () => {
    try {
      isLoading.value = true
      await fetchOrganizationInformations()
      if (isOrganizationAdmin.value) {
        loadSalesForceScript()
        await fetchKycFeatures()
        await fetchKycProvider()
      }
      sendDataLayer()
      identifyUser()
    } finally {
      isLoading.value = false
    }
  }

  onMounted(() => {
    if (route.value.query?.redirect === '403') {
      notifyError(new Error('Redirect because of 403'), {
        title: i18n.t(`layouts.fetch.errorUnauthorizedTitle`),
        body: i18n.t('layouts.fetch.errorUnauthorizedBody')
      })

      router.replace({ redirect: null })
    }
  })

  return {
    showAccessibilitySettings,
    showSidebar,
    isLoading,
    closeSidebar,
    toggleSidebar,
    openAccessibilitySettings,
    closeAccessibilitySettings,
    setLayout
  }
}
