import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _15626b07 = () => interopDefault(import('../src/pages/associations.vue' /* webpackChunkName: "pages/associations" */))
const _248a8320 = () => interopDefault(import('../src/pages/error/_code.vue' /* webpackChunkName: "pages/error/_code" */))
const _1db27515 = () => interopDefault(import('../src/pages/_organization/accueil/index.vue' /* webpackChunkName: "pages/_organization/accueil/index" */))
const _63cfe850 = () => interopDefault(import('../src/pages/_organization/authentification/index.vue' /* webpackChunkName: "pages/_organization/authentification/index" */))
const _f2a48f9e = () => interopDefault(import('../src/pages/_organization/integrations.vue' /* webpackChunkName: "pages/_organization/integrations" */))
const _01db7506 = () => interopDefault(import('../src/pages/_organization/paiements.vue' /* webpackChunkName: "pages/_organization/paiements" */))
const _e43721f8 = () => interopDefault(import('../src/pages/_organization/recu-fiscal.vue' /* webpackChunkName: "pages/_organization/recu-fiscal" */))
const _a30b2820 = () => interopDefault(import('../src/pages/_organization/recu-fiscal/preference-envoi.vue' /* webpackChunkName: "pages/_organization/recu-fiscal/preference-envoi" */))
const _7f82f5cc = () => interopDefault(import('../src/pages/_organization/recu-fiscal/reduction-impot.vue' /* webpackChunkName: "pages/_organization/recu-fiscal/reduction-impot" */))
const _67c0c582 = () => interopDefault(import('../src/pages/_organization/accueil/bienvenue.vue' /* webpackChunkName: "pages/_organization/accueil/bienvenue" */))
const _15e7cff6 = () => interopDefault(import('../src/pages/_organization/authentification/etape.vue' /* webpackChunkName: "pages/_organization/authentification/etape" */))
const _50c2cc77 = () => interopDefault(import('../src/pages/_organization/authentification/etape/association.vue' /* webpackChunkName: "pages/_organization/authentification/etape/association" */))
const _60b5ad4c = () => interopDefault(import('../src/pages/_organization/authentification/etape/banque.vue' /* webpackChunkName: "pages/_organization/authentification/etape/banque" */))
const _44997ed2 = () => interopDefault(import('../src/pages/_organization/authentification/etape/mandataire.vue' /* webpackChunkName: "pages/_organization/authentification/etape/mandataire" */))
const _41ac49a0 = () => interopDefault(import('../src/pages/_organization/mon-compte/parametres.vue' /* webpackChunkName: "pages/_organization/mon-compte/parametres" */))
const _e7b95a68 = () => interopDefault(import('../src/pages/_organization/mon-compte/versements.vue' /* webpackChunkName: "pages/_organization/mon-compte/versements" */))
const _2d84f912 = () => interopDefault(import('../src/pages/_organization/_type.vue' /* webpackChunkName: "pages/_organization/_type" */))
const _7abd568c = () => interopDefault(import('../src/pages/_organization/_type/index.vue' /* webpackChunkName: "pages/_organization/_type/index" */))
const _bbfaf4b2 = () => interopDefault(import('../src/pages/_organization/_type/creation.vue' /* webpackChunkName: "pages/_organization/_type/creation" */))
const _69701a2c = () => interopDefault(import('../src/pages/_organization/_type/creation/index.vue' /* webpackChunkName: "pages/_organization/_type/creation/index" */))
const _300950d7 = () => interopDefault(import('../src/pages/_organization/_type/creation/bienvenue.vue' /* webpackChunkName: "pages/_organization/_type/creation/bienvenue" */))
const _1464210b = () => interopDefault(import('../src/pages/_organization/_type/_form.vue' /* webpackChunkName: "pages/_organization/_type/_form" */))
const _0eb4e257 = () => interopDefault(import('../src/pages/_organization/_type/_form/administrateurs.vue' /* webpackChunkName: "pages/_organization/_type/_form/administrateurs" */))
const _5628d2c1 = () => interopDefault(import('../src/pages/_organization/_type/_form/diffusion.vue' /* webpackChunkName: "pages/_organization/_type/_form/diffusion" */))
const _c413570e = () => interopDefault(import('../src/pages/_organization/_type/_form/statistiques.vue' /* webpackChunkName: "pages/_organization/_type/_form/statistiques" */))
const _1962e52b = () => interopDefault(import('../src/pages/_organization/_type/_form/_actionType.vue' /* webpackChunkName: "pages/_organization/_type/_form/_actionType" */))
const _7c0821c7 = () => interopDefault(import('../src/pages/_organization/_type/_form/_actionType/_step.vue' /* webpackChunkName: "pages/_organization/_type/_form/_actionType/_step" */))
const _75445dce = () => interopDefault(import('../src/pages/_organization/_type/_form/_actionType/_step/_tab.vue' /* webpackChunkName: "pages/_organization/_type/_form/_actionType/_step/_tab" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/associations",
    component: _15626b07,
    name: "associations___fr"
  }, {
    path: "/error/:code?",
    component: _248a8320,
    name: "error-code___fr"
  }, {
    path: "/:organization?/accueil",
    component: _1db27515,
    name: "organization-accueil___fr"
  }, {
    path: "/:organization?/authentification",
    component: _63cfe850,
    name: "organization-authentification___fr"
  }, {
    path: "/:organization?/integrations",
    component: _f2a48f9e,
    name: "organization-integrations___fr"
  }, {
    path: "/:organization?/paiements",
    component: _01db7506,
    name: "organization-paiements___fr"
  }, {
    path: "/:organization?/recu-fiscal",
    component: _e43721f8,
    name: "organization-recu-fiscal___fr",
    children: [{
      path: "preference-envoi",
      component: _a30b2820,
      name: "organization-recu-fiscal-preference-envoi___fr"
    }, {
      path: "reduction-impot",
      component: _7f82f5cc,
      name: "organization-recu-fiscal-reduction-impot___fr"
    }]
  }, {
    path: "/:organization?/accueil/bienvenue",
    component: _67c0c582,
    name: "organization-accueil-bienvenue___fr"
  }, {
    path: "/:organization?/authentification/etape",
    component: _15e7cff6,
    name: "organization-authentification-etape___fr",
    children: [{
      path: "association",
      component: _50c2cc77,
      name: "organization-authentification-etape-association___fr"
    }, {
      path: "banque",
      component: _60b5ad4c,
      name: "organization-authentification-etape-banque___fr"
    }, {
      path: "mandataire",
      component: _44997ed2,
      name: "organization-authentification-etape-mandataire___fr"
    }]
  }, {
    path: "/:organization?/mon-compte/parametres",
    component: _41ac49a0,
    name: "organization-mon-compte-parametres___fr"
  }, {
    path: "/:organization?/mon-compte/versements",
    component: _e7b95a68,
    name: "organization-mon-compte-versements___fr"
  }, {
    path: "/:organization?/:type?",
    component: _2d84f912,
    children: [{
      path: "",
      component: _7abd568c,
      name: "organization-type___fr"
    }, {
      path: "creation",
      component: _bbfaf4b2,
      children: [{
        path: "",
        component: _69701a2c,
        name: "organization-type-creation___fr"
      }, {
        path: "bienvenue",
        component: _300950d7,
        name: "organization-type-creation-bienvenue___fr"
      }]
    }, {
      path: ":form",
      component: _1464210b,
      name: "organization-type-form___fr",
      children: [{
        path: "administrateurs",
        component: _0eb4e257,
        name: "organization-type-form-administrateurs___fr"
      }, {
        path: "diffusion",
        component: _5628d2c1,
        name: "organization-type-form-diffusion___fr"
      }, {
        path: "statistiques",
        component: _c413570e,
        name: "organization-type-form-statistiques___fr"
      }, {
        path: ":actionType?",
        component: _1962e52b,
        name: "organization-type-form-actionType___fr",
        children: [{
          path: ":step?",
          component: _7c0821c7,
          name: "organization-type-form-actionType-step___fr",
          children: [{
            path: ":tab?",
            component: _75445dce,
            name: "organization-type-form-actionType-step-tab___fr"
          }]
        }]
      }]
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
