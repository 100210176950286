import { computed } from 'vue'
import useStoreData from '@/composables/useStoreData'
import { Role } from '@/helpers/enums'

export default () => {
  const { organization } = useStoreData()

  const isOrganizationAdmin = computed(() => organization.value.role === Role.ORGANIZATION_ADMIN)
  const isFormAdmin = computed(() => organization.value.role === Role.FORM_ADMIN)

  return {
    isOrganizationAdmin,
    isFormAdmin
  }
}
