import { enum as enums } from '@ha/helpers'
import { FormType } from '@/helpers/tsenums'

const checkParams = (formType: FormType) => {
  if (!formType) throw new Error('Missing form param')
}

export const isCrowdfunding = (formType: FormType) => {
  checkParams(formType)
  return formType === enums.FormType.CROWDFUNDING
}

export const isEvent = (formType: FormType) => {
  checkParams(formType)
  return formType === enums.FormType.EVENT
}

export const isMembership = (formType: FormType) => {
  checkParams(formType)
  return formType === enums.FormType.MEMBERSHIP
}

export const isShop = (formType: FormType) => {
  checkParams(formType)
  return formType === enums.FormType.SHOP
}

export const isSale = (formType: FormType) => {
  checkParams(formType)
  return formType === enums.FormType.PAYMENTFORM
}

export const isDonation = (formType: FormType) => {
  checkParams(formType)
  return formType === enums.FormType.DONATION
}

export const isCheckout = (formType: FormType) => {
  checkParams(formType)
  return formType === enums.FormType.CHECKOUT
}
