import { find, map, filter, findIndex } from 'lodash-es'
import { Status, FormType, Picture, StoreRouteParams } from '../helpers/tsenums'
import { Tier, TierState } from '@/components/tiers/Tiers.interface'
import getStoreFormKey from '../helpers/getStoreFormKey'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { functions } from '@ha/helpers'
import { disablePaymentTermsForFreeTiers } from '@/components/payment-parameters/paymentParameters'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}
interface Source {
  form: string | null
  organization: string | null
}

class State {
  items: Tier[] = []
  status: Status = Status.SUCCESS
  fetchedFrom: Source = {
    form: null,
    organization: null
  }
}

export const state = () => new State()

export const getters = {
  hasLoadedTiers: (state) => (formSlug, orgSlug) => {
    state.fetchedFrom.form === formSlug && state.fetchedFrom.organization === orgSlug
  }
}

export const mutations = <MutationTree<State>>{
  SET_TIERS(
    state,
    { tiers, orgSlug, formSlug }: { tiers: Tier[]; orgSlug: string; formSlug: string }
  ) {
    state.items = tiers
    state.fetchedFrom.form = formSlug
    state.fetchedFrom.organization = orgSlug
  },
  SET_TIERS_STATUS(state, { status }: { status: Status }) {
    state.status = status
  },
  ADD_TIER(state, { tier }: { tier: Tier }) {
    state.items = [...state.items, tier]
  },
  DELETE_TIER(state, { id }: { id: number }) {
    state.items = filter(state.items, (tier) => tier.id !== id)
  },
  ENABLE_TIER(state, { id }: { id: number }) {
    const tier = find(state.items, (item) => item.id === id)
    if (tier) tier.state = TierState.ENABLED
  },
  DISABLE_TIER(state, { id }: { id: number }) {
    const tier = find(state.items, (item) => item.id === id)
    if (tier) tier.state = TierState.DISABLED
  },
  SET_TIER(state, { tier }: { tier: Tier }) {
    state.items.splice(
      findIndex(state.items, (item) => tier.id === item.id),
      1,
      tier
    )
  },
  SET_TIER_IMAGE(state, { id, picture }: { id: number; picture: Picture }) {
    const tierIndex = state.items.findIndex((tier) => tier.id === id)
    if (tierIndex === -1) return

    const updatedTier = { ...state.items[tierIndex], picture }
    state.items.splice(tierIndex, 1, updatedTier)
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchTiers(
    { commit, getters },
    { routeParams, forceReload = false }: { routeParams: StoreRouteParams; forceReload: boolean }
  ): Promise<void> {
    const { orgSlug, formSlug, type } = routeParams
    if (!forceReload && getters.hasLoadedTiers(formSlug, orgSlug)) return Promise.resolve()

    try {
      const response = await this.$api.get(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/tiers`
      )
      const tiers = disablePaymentTermsForFreeTiers(response.data)

      const key = getStoreFormKey({ orgSlug, formSlug, type })
      commit('SET_TIERS', { tiers, orgSlug, formSlug })
      commit('SET_TIERS_STATUS', { status: Status.SUCCESS })
      commit('forms/SET_FORM_TIERS', { key, tiers: tiers || [] }, { root: true })
    } catch (error) {
      commit('SET_TIERS_STATUS', { status: Status.ERROR })
      throw error
    }
  },

  createTier(
    { commit, state },
    {
      orgSlug,
      formSlug,
      type,
      tier,
      setFormTier = false
    }: { orgSlug: string; formSlug: string; type: FormType; tier: Tier; setFormTier: boolean }
  ) {
    commit('SET_TIERS_STATUS', { status: Status.LOADING })

    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/tiers`, tier)
      .then((response) => {
        commit('SET_TIERS_STATUS', { status: Status.SUCCESS })
        commit('ADD_TIER', { tier: response.data })
        if (setFormTier) {
          const key = getStoreFormKey({ orgSlug, formSlug, type })
          commit('forms/SET_FORM_TIERS', { key, tiers: state.items || [] }, { root: true })
        }
        return response.data
      })
      .catch((error) => {
        commit('SET_TIERS_STATUS', { status: Status.ERROR })
        throw error
      })
  },

  updateTier(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      tier
    }: { orgSlug: string; formSlug: string; type: FormType; tier: Tier }
  ) {
    commit('SET_TIERS_STATUS', { status: Status.LOADING })

    return functions.lockContainer('updateTier', () =>
      this.$api
        .put(`/organizations/${orgSlug}/forms/${type}/${formSlug}/tiers/${tier.id}`, tier)
        .then((response) => {
          commit('SET_TIER', { tier: response.data })
          commit('SET_TIERS_STATUS', { status: Status.SUCCESS })

          return response.data
        })
        .catch((error) => {
          commit('SET_TIERS_STATUS', { status: Status.ERROR })
          throw error
        })
    )()
  },

  deleteTier(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    commit('SET_TIERS_STATUS', { status: Status.LOADING })

    return this.$api
      .delete(`/organizations/${orgSlug}/forms/${type}/${formSlug}/tiers/${id}`)
      .then(() => {
        commit('DELETE_TIER', { id })
        commit('SET_TIERS_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_TIERS_STATUS', { status: Status.ERROR })
        throw error
      })
  },

  enableTier(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    commit('SET_TIERS_STATUS', { status: Status.LOADING })

    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/tiers/${id}/enable`)
      .then(() => {
        commit('ENABLE_TIER', { id })
        commit('SET_TIERS_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_TIERS_STATUS', { status: Status.ERROR })
        throw error
      })
  },

  disableTier(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    commit('SET_TIERS_STATUS', { status: Status.LOADING })

    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/tiers/${id}/disable`)
      .then(() => {
        commit('DISABLE_TIER', { id })
        commit('SET_TIERS_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_TIERS_STATUS', { status: Status.ERROR })
        throw error
      })
  },

  orderTiers(
    { commit, state },
    {
      orgSlug,
      formSlug,
      type,
      tiersIds
    }: { orgSlug: string; formSlug: string; type: FormType; tiersIds: number[] }
  ) {
    commit('SET_TIERS_STATUS', { status: Status.LOADING })

    return this.$api
      .put(`/organizations/${orgSlug}/forms/${type}/${formSlug}/hierarchy`, {
        unGroupedTiers: tiersIds,
        groups: []
      })
      .then(() => {
        const disabledTiers = filter(state.items, (item) => item.state === TierState.DISABLED)
        const newTiers = map(tiersIds, (id) => find(state.items, { id }))
        commit('SET_TIERS', { tiers: [...newTiers, ...disabledTiers] })
        commit('SET_TIERS_STATUS', { status: Status.SUCCESS })
      })
      .catch((error) => {
        commit('SET_TIERS_STATUS', { status: Status.ERROR })
        throw error
      })
  },

  addTierImage(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id,
      image
    }: { orgSlug: string; formSlug: string; type: FormType; id: number; image: File }
  ) {
    const formData = new FormData()
    formData.append('file', image)

    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/tiers/${id}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        commit('SET_TIER_IMAGE', { id: id, picture: response.data })
        return response.data.fileName
      })
  }
}
