import { computed } from 'vue'
import { useStore } from '@nuxtjs/composition-api'
import useRouteParams from '@/composables/useRouteParams'
import getStoreFormKey from '@/helpers/getStoreFormKey'

export default params => {
  const { organizationSlug, formSlug, formType } = useRouteParams()
  const store = useStore()

  const organizationSlugParams = computed(() => params?.organizationSlug || organizationSlug.value)
  const formSlugParams = computed(() => params?.formSlug || formSlug.value)
  const formTypeParams = computed(() => params?.formType || formType.value)
  const storeRouteParams = computed(() => ({
    orgSlug: organizationSlugParams.value,
    formSlug: formSlugParams.value,
    type: formTypeParams.value
  }))
  const storeRouteKey = computed(() => getStoreFormKey(storeRouteParams.value))
  const form = computed(() => {
    if (!formSlugParams.value) return null
    return store.state.forms.entities[storeRouteKey.value]
  })
  const organization = computed(
    () => store.state.organizations.enriched[organizationSlugParams.value] || {}
  )
  const tiers = computed(() => store.state.tiers.items || [])
  const options = computed(() => store.state.options.items || [])
  const discountCodes = computed(() => store.state.discountCodes.items || [])
  const formTypes = computed(
    () => store.state.organizations.formTypes[organizationSlugParams.value] || []
  )
  const customFields = computed(() => store.state.customFields.items || [])
  const participants = computed(() => store.state.attendees.entities || [])
  const participantsTotal = computed(() => store.state.attendees.total)
  const compliance = computed(() => store.state.compliance)
  const user = computed(() => store.state.user.user || {})
  const provider = computed(() => store.getters['compliance/dataLayerProviderName'])

  return {
    form,
    organization,
    tiers,
    options,
    discountCodes,
    formTypes,
    storeRouteParams,
    customFields,
    participants,
    participantsTotal,
    compliance,
    storeRouteKey,
    user,
    provider
  }
}
