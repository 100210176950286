import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { DateOperator } from '@ha/helpers'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

class State {
  isUserTwoFaVerified: Boolean = false
}

export const state = () => new State()
export const mutations = <MutationTree<State>>{
  SET_IS_USER_TWO_FA_VERIFIED(state, isTwoFaVerified: Boolean) {
    state.isUserTwoFaVerified = isTwoFaVerified
  }
}

export const actions = <ActionTree<State, any>>{
  /**
   * Verify connected user's password
   * @param {string} password
   */
  verifyPassword(_, { password }: { password: string }) {
    return this.$api.post('/users/me/password/verify', {
      password
    })
  },

  verifyTwoFa({ commit }) {
    return this.$api
      .get('/users/me/2fa')
      .then((response) => {
        const today = new DateOperator()
        const expirationDate = response.data?.expirationDate
          ? new DateOperator(response.data?.expirationDate).subtract(10, 'second')
          : null
        const isUserVerified = expirationDate ? !today.isAfter(expirationDate) : false
        commit('SET_IS_USER_TWO_FA_VERIFIED', isUserVerified)
      })
      .catch((error) => {
        commit('SET_IS_USER_TWO_FA_VERIFIED', false)
        throw error
      })
  },

  sendSmsCode({ commit }) {
    commit('SET_IS_USER_TWO_FA_VERIFIED', false)
    return this.$api.post('/users/me/sms-otp/init')
  },

  validateSmsCode({ commit }, oneTimeToken: string) {
    return this.$api
      .post('/users/me/sms-otp/validate', { oneTimeToken })
      .then(() => {
        commit('SET_IS_USER_TWO_FA_VERIFIED', true)
      })
      .catch((error) => {
        commit('SET_IS_USER_TWO_FA_VERIFIED', false)
        throw error
      })
  },

  // Check phone number is conform and send email with OTP to user
  verifyPhoneNumberAndSendEmailCode({ state }, phone: string) {
    return this.$api.post('/users/me/phone/change', {
      phone
    })
  },

  // Check OTP is conform and save new phone number
  validateEmailCodeAndUpdatePhoneNumber({ dispatch }, oneTimeToken: string) {
    return new Promise((resolve, reject) => {
      this.$api
        .post('/users/me/phone/verify', { oneTimeToken })
        .then(() => {
          // Fetch user to refresh phone number in store
          dispatch('user/fetchUser', true, { root: true })
            .then(() => {
              resolve(true)
            })
            .catch((errorFetchUser) => reject(errorFetchUser))
        })
        .catch((errorValidate) => reject(errorValidate))
    })
  }
}
