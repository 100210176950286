import { filter, findIndex } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { FormType, StoreRouteParams, ExtraOption } from '../helpers/tsenums'
import { isEvent, isMembership, isShop } from '@/helpers/forms/getFormType'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

interface Source {
  form: string | null
  organization: string | null
}

class State {
  items: ExtraOption[] = []
  fetchedFrom: Source = {
    form: null,
    organization: null
  }
}

export const state = () => new State()
export const getters = {
  hasLoadedOptions: (state) => (formSlug, orgSlug) => {
    return state.fetchedFrom.form === formSlug && state.fetchedFrom.organization === orgSlug
  }
}

export const mutations = <MutationTree<State>>{
  SET_OPTIONS(
    state,
    { options, formSlug, orgSlug }: { options: ExtraOption[]; formSlug: string; orgSlug: string }
  ) {
    state.items = options
    state.fetchedFrom.form = formSlug
    state.fetchedFrom.organization = orgSlug
  },
  ADD_OPTION(state, { option }: { option: ExtraOption }) {
    state.items = [...state.items, option]
  },
  DELETE_OPTION(state, { id }: { id: number }) {
    state.items = filter(state.items, (option) => option.id !== id)
  },
  DISABLE_OPTION(state, { id }: { id: number }) {
    const optionIndex = state.items.findIndex((item) => item.id === id)
    state.items[optionIndex].state = 'Disabled'
  },
  ENABLE_OPTION(state, { id }: { id: number }) {
    const optionIndex = state.items.findIndex((item) => item.id === id)
    state.items[optionIndex].state = 'Enabled'
  },
  SET_OPTION(state, { option }: { option: ExtraOption }) {
    state.items.splice(
      findIndex(state.items, (item) => option.id === item.id),
      1,
      option
    )
  }
}

export const actions = <ActionTree<State, any>>{
  async fetchOptions(
    { commit, getters },
    { routeParams, forceReload = false }: { routeParams: StoreRouteParams; forceReload: false }
  ) {
    const { orgSlug, formSlug, type } = routeParams
    if (!forceReload && getters.hasLoadedOptions(formSlug, orgSlug)) return Promise.resolve()

    /* Extra options only exist for membership, shop and event */
    if (!isMembership(type) && !isShop(type) && !isEvent(type)) {
      commit('SET_OPTIONS', { options: [], formSlug, orgSlug })
      return Promise.resolve()
    }

    try {
      const response = await this.$api.get(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/additional-options`
      )

      commit('SET_OPTIONS', {
        options: response.data,
        formSlug,
        orgSlug
      })
    } catch (error) {
      throw error
    }
  },

  createOption(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      option
    }: { orgSlug: string; formSlug: string; type: FormType; option: ExtraOption }
  ) {
    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/additional-options`, option)
      .then((response) => {
        commit('ADD_OPTION', {
          option: response.data
        })
      })
      .catch((error) => {
        throw error
      })
  },

  updateOption(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      option
    }: { orgSlug: string; formSlug: string; type: FormType; option: ExtraOption }
  ) {
    return this.$api
      .put(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/additional-options/${option.id}`,
        option
      )
      .then((response) => {
        commit('SET_OPTION', {
          option: response.data
        })
      })
      .catch((error) => {
        throw error
      })
  },

  deleteOption(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    return this.$api
      .delete(`/organizations/${orgSlug}/forms/${type}/${formSlug}/additional-options/${id}`)
      .then(() => {
        commit('DELETE_OPTION', {
          id
        })
      })
      .catch((error) => {
        throw error
      })
  },

  disableOption(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/additional-options/${id}/disable`)
      .then(() => {
        commit('DISABLE_OPTION', {
          id
        })
      })
      .catch((error) => {
        throw error
      })
  },

  enableOption(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      id
    }: { orgSlug: string; formSlug: string; type: FormType; id: number }
  ) {
    return this.$api
      .post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/additional-options/${id}/enable`)
      .then(() => {
        commit('ENABLE_OPTION', {
          id
        })
      })
      .catch((error) => {
        throw error
      })
  }
}
